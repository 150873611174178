<div class='control'>
    <div class='form-group'>
        <label [for]="'i-' + id" [class.default]='!labelName' [formGroup]='form'>
			<span *ngIf='labelName && showLabel' class='custom-label control-label'>{{
                labelName
                }}</span>
            <input
                #element
                [id]="'i-' + id"
                type="text"
                [attr.name]='name'
                [formControlName]='name'
                [placeholder]='placeholder || ""'
                [setValue]='inputModel'
                (keyup)='onChange($event, element.value)'
                class='form-control custom-input'
                [ngClass]="{'mt-0': !showLabel}"
                [attr.disabled]="disabled == true ? true : null"
            />
            <span *ngIf="showLoading" class="spinner-border text-primary" role="status"></span>
            <div [ngClass]="itemsList.length > 0 ? '' : 'drop-area-hide'" class="drop-area">
                <div *ngFor="let item of itemsList; let i = index"  [id]="'i-' + item.value" class="drop-item" (click)="item.value === '00' ? semDado() : selectItem(item)">{{item.label}}</div>
            </div>
        </label>
        <span *ngIf='callout' class='callout'>{{ callout }}</span>
        <span
            *ngIf='form.controls[name || ""].invalid &&
             (form.controls[name || ""].touched || form.controls[name || ""].dirty)'
            class='control-error control-error-autocomplete'
            [setFormControlError]='{objError: form.controls[name || ""].errors, control: labelName}'
        >
        </span>
    </div>
</div>


<div class="car">
    <app-banner-form [title]="titleBanner" [description]="descriptionBanner" >
        <div form>
            <form action='#'
                  autocomplete='off'
                  (ngSubmit)='onSubmit()'
                  [formGroup]='form'
                  class="formCustom">
                <input type="hidden" name="hubUtmz" id="hubUtmz" value="" />
                <div class="progress-wrap">
                    <ul class="checkout-bar">
                        <li class="progressbar-dots" [ngClass]="{'active': step === 1, 'complete': step > 1}">
                            <div class="line-progress-bar" [ngClass]="{'active': step === 1, 'complete': step > 1}"></div>
                            <span>1</span>
                        </li>
                        <li class="progressbar-dots" [ngClass]="{'active': step === 2, 'complete': step > 2}">
                            <div class="line-progress-bar" [ngClass]="{'active': step === 2, 'complete': step > 2}"></div>
                            <span>2</span>
                        </li>
                        <li class="progressbar-dots" [ngClass]="{'active': step === 3, 'complete': step >= 3}">
                            <div class="line-progress-bar" [ngClass]="{'active': step === 3, 'complete': step >= 3}"></div>
                            <span>3</span>
                        </li>
                        <li class="progressbar-dots last" [ngClass]="{'active': step ===  4, 'complete': step > 3}">
                            <span>4</span>
                        </li>
                    </ul>

                </div>
                <div class="wrapper-steps">
                    <div class="wrapper-steps__step" [ngClass]="step === 1 ? 'show' : 'hide'" id="step1" >
                        <div class="form-content">
                            <div class="field">
                                <app-input
                                    id='name'
                                    name='name'
                                    placeholder='Nome'
                                    labelName='Nome'
                                    [form]='form'
                                    (emmitBlur)='sendGA("Form_Auto_Palmeiras", "Nome")'
                                ></app-input>
                            </div>
                            <div class="field">
                                <app-input
                                    id='registrationNumber'
                                    name='registrationNumber'
                                    placeholder='CPF'
                                    labelName='CPF'
                                    [form]='form'
                                    inputMask='000.000.000-00'
                                    (emmitBlur)='sendGA("Form_Auto_Palmeiras", "CPF")'
                                ></app-input>
                            </div>
                            <div class="field">
                                <app-input
                                    id='email'
                                    name='email'
                                    typeInput='email'
                                    placeholder='E-mail'
                                    labelName='E-mail'
                                    [form]='form'
                                    (emmitBlur)='sendGA("Form_Auto_Palmeiras", "E-mail")'
                                ></app-input>
                                <span
                                *ngIf='!changeEmail(form.get("email")?.value)'
                                class='control-error diferent-email'
                                >
                                INSIRA UM EMAIL VÁLIDO
                                </span>
                            </div>
                            <div class="field">
                                <app-input
                                    id='confirm_email'
                                    name='confirm_email'
                                    typeInput='email'
                                    placeholder='Confirmar e-mail'
                                    labelName='Confirmar e-mail'
                                    [form]='form'
                                    (emmitBlur)='sendGA("Form_Auto_Palmeiras", "Confirmar e-mail")'
                                ></app-input>
                                <span
                                    *ngIf='!changeEmail(form.get("confirm_email")?.value)'
                                    class='control-error diferent-email'
                                >
                                INSIRA UM EMAIL VÁLIDO
                                </span>
                                <span
                                    *ngIf=' form.get("confirm_email")?.value && (form.get("email")?.value !== form.get("confirm_email")?.value)'
                                    class='control-error diferent-email'
                                >
                                OS EMAILS PRECISAM SER IGUAIS
                                </span>
                            </div>
                            <div class="field">
                                <app-input
                                    id='phone'
                                    name='phone'
                                    labelName='Telefone'
                                    placeholder='Telefone'
                                    inputMask='(00) 00000-0000'
                                    [form]='form'
                                    (emmitBlur)='sendGA("Form_Auto_Palmeiras", "Telefone")'
                                ></app-input>
                                <span
                                    *ngIf='form.get("phone")?.value && form.get("phone")?.value.length < 11'
                                    class='control-error diferent-email'
                                >
                                POR FAVOR, INSIRA UM TELEFONE VÁLIDO
                                </span>
                            </div>
                            <div class="contact">
                                <h3>Como você prefere receber o nosso contato?</h3>
                                <div class="options">
                                    <div class="option">
                                        <app-radio
                                            id='whatsapp-contact'
                                            name="message"
                                            labelName='Mensagem'
                                            [value]="'whatsapp'"
                                            [form]='form'
                                            [changeModel]="changeRadio"
                                            [invalid]='form.get("name")?.value && form.get("registrationNumber")?.value && form.get("email")?.value && form.get("confirm_email")?.value && form.get("phone")?.value && form.get("registrationNumber")?.value && (form.get("confirm_email")?.value === form.get("email")?.value) && !form.get("message")?.value'
                                        ></app-radio>
                                    </div>
                                    <div class="option">
                                        <app-radio
                                            id='email-contact'
                                            name="message"
                                            labelName='E-mail'
                                            [value]="'email'"
                                            [form]='form'
                                            [changeModel]="changeRadio"
                                            [invalid]='form.get("name")?.value && form.get("registrationNumber")?.value && form.get("email")?.value && form.get("confirm_email")?.value && form.get("phone")?.value && form.get("registrationNumber")?.value && (form.get("confirm_email")?.value === form.get("email")?.value) && !form.get("message")?.value'
                                        ></app-radio>
                                    </div>
                                    <div class="option">
                                        <app-radio
                                            id='email-phone'
                                            name="message"
                                            labelName='Ligação'
                                            [value]="'ligacao'"
                                            [form]='form'
                                            [changeModel]="changeRadio"
                                            [invalid]='form.get("name")?.value && form.get("registrationNumber")?.value && form.get("email")?.value && form.get("confirm_email")?.value && form.get("phone")?.value && form.get("registrationNumber")?.value && (form.get("confirm_email")?.value === form.get("email")?.value) && !form.get("message")?.value'
                                        ></app-radio>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="action-button">
                            <button class="next-btn next-btn1" type="button" [disabled]="disabled" (click)="nextStep()">
                                Próximo
                            </button>
                        </div>
                    </div>
                    <div class="wrapper-steps__step" [ngClass]="step === 2 ? 'show' : 'hide'" id="step2" >
                        <div class="form-content">
                            <div class="field">
                                <app-input
                                    id='zipCode'
                                    name='zipCode'
                                    labelName='CEP'
                                    placeholder='CEP'
                                    [form]='form'
                                    inputMask='00000-000'
                                    (emmitEvent)='getAddressByZipCode($event)'
                                    (emmitBlur)='sendGA("Form_Auto_Palmeiras", "CEP")'
                                ></app-input>
                                <span *ngIf="form.get('zipCode')?.value && errorZipcode" class="error-cep">POR FAVOR, INSIRA UM CEP VÁLIDO</span>
                            </div>
                            <div class="field">
                                <app-input
                                    id='state'
                                    name='state'
                                    placeholder='Estado'
                                    labelName='Estado'
                                    [form]='form'
                                    [disabled]='true'
                                ></app-input>
                            </div>
                            <div class="field">
                                <app-input
                                    id='city'
                                    name='city'
                                    placeholder='Cidade'
                                    labelName='Cidade'
                                    [form]='form'
                                    [disabled]='true'
                                ></app-input>
                            </div>
                        </div>
                        <div class="action-button">
                            <button class="next-btn next-btn1" type="button" [disabled]="disabled" (click)="nextStep()">
                                Próximo
                            </button>
                            <button class="back-step mt-3" type="button" (click)="backStep()">
                                voltar
                            </button>
                        </div>
                    </div>
                    <div class="wrapper-steps__step" [ngClass]="step === 3 ? 'show' : 'hide'" id="step3" >
                        <div>
                            <div>
                                <div class="field">
                                    <app-select
                                        id='interest1Title'
                                        name='interest1Title'
                                        [options]='types'
                                        labelName=''
                                        placeholder='Selecione o tipo de veículo'
                                        inputModel=''
                                        [form]='form'
                                        (change)='getYears()'
                                    ></app-select>
                                </div>
                                <div class="field">
                                    <app-select
                                        id='nationality'
                                        name='nationality'
                                        [options]='nationalities'
                                        labelName=''
                                        placeholder='Selecione se é nacional ou importado'
                                        inputModel=''
                                        [form]='form'
                                        (change)='getYears()'
                                    ></app-select>
                                </div>
                                <div class="field">
                                    <app-select
                                        id='interest6Title'
                                        name='interest6Title'
                                        [options]='years'
                                        [disabled]='years.length <= 1'
                                        labelName=''
                                        placeholder='Selecione o ano do veículo'
                                        inputModel=''
                                        [form]='form'
                                        (change)='form.get("interest3Title")?.setValue("")'
                                    ></app-select>
                                </div>
                                <div class="field modelo">
                                    <app-select-autocomplete
                                        id='interest3Title'
                                        name='interest3Title'
                                        placeholder='Modelo'
                                        labelName=''
                                        [disabled]='!form.get("interest6Title")?.value'
                                        [itemsList]='modelos'
                                        [form]='form'
                                        [showLoading]='showLoading'
                                        (search)='onSearch($event)'
                                        (select)='onSelect($event)'
                                    ></app-select-autocomplete>
                                    <span
                                    class="custom-tooltip btn-open"
                                    (click)="openModalModel()"
                                >i</span>
                                </div>
                                <div class="field">
                                    <app-input
                                        id='interest5Title'
                                        name='interest5Title'
                                        placeholder='Placa do veículo'
                                        labelName='Placa do veículo'
                                        [form]='form'
                                        [max]='8'
                                        (emmitBlur)='sendGA("Form_Auto_Palmeiras", "Placa do veículo")'
                                    ></app-input>
                                    <span
                                    *ngIf='form.get("interest6Title")?.value && (form.get("interest6Title")?.value !== "0") && (!form.get("interest5Title")?.valid)'
                                    class='control-error diferent-email'
                                    >
                                    CAMPO OBRIGATÓRIO
                                    </span>
                                </div>


                                <div class="row">
                                    <div class="col-12 mb-3">
                                        <div class="options justify-content-start">
                                            <div class="option ps-0">
                                                <app-radio
                                                    id='safeNew'
                                                    name="safeType"
                                                    labelName='Seguro Novo'
                                                    [value]="'Seguro Novo'"
                                                    [form]='form'
                                                    [invalid]='form.get("interest1Title")?.value && form.get("nationality")?.value && form.get("interest5Title")?.value && form.get("interest3Title")?.value && form.get("interest6Title")?.value && !form.get("safeType")?.value'
                                                ></app-radio>
                                            </div>
                                            <div class="option">
                                                <app-radio
                                                    id='safeRenew'
                                                    name="safeType"
                                                    labelName='Renovação de Seguro'
                                                    [value]="'Renovação de Seguro'"
                                                    [form]='form'
                                                    [invalid]='form.get("interest1Title")?.value && form.get("nationality")?.value && form.get("interest5Title")?.value && form.get("interest3Title")?.value && form.get("interest6Title")?.value && !form.get("safeType")?.value'
                                                ></app-radio>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 mb-3">
                                        <div class="option legal-agreement">
                                            <app-checkbox
                                                id='consent'
                                                name="consent"
                                                labelName=""
                                                [form]='form'
                                                [invalid]='(form.get("interest1Title")?.value && form.get("nationality")?.value && form.get("interest5Title")?.value && form.get("interest3Title")?.value && form.get("interest6Title")?.value && (!form.get("safeType")?.value && !form.get("consent")?.value)) || (form.get("interest1Title")?.value && form.get("nationality")?.value && form.get("interest5Title")?.value && form.get("interest3Title")?.value && form.get("interest6Title")?.value && form.get("safeType")?.value && !form.get("consent")?.value)'                                         >
                                            </app-checkbox>
                                            <p>
                                                <strong>Declaro ciência sobre o tratamento dos dados inseridos por mim no formulário,
                                                        na forma estabelecida pela <a href="https://www.allianz.com.br/politicas-do-sistema.html#privacidade" class="custom-link">Politica de Privacidade</a> e
                                                    <a href="https://www.allianz.com.br/politicas-do-sistema.html#cookies" class="custom-link">Politica de Cookies</a> da Allianz.</strong>
                                                Autorizo a Allianz a compartilhar minhas informações com corretores credenciados para
                                                cotação de produtos e serviços. Além disso, aceito receber comunicações sobre promoções
                                                da Allianz e seus parceiros.
                                                <span
                                                    class="custom-tooltip btn-open"
                                                    (click)="openModal()"
                                                >i</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="action-button">
                                <button class="submit-btn mb-3" type="submit" [disabled]="disabled">
                                    QUERO UMA COTAÇÃO
                                </button>
                                <button class="back-step" type="button" (click)="backStep()">
                                    voltar
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="wrapper-steps__step" [ngClass]="step === 4 ? 'show' : 'hide'" id="step4" >
                        <div *ngIf="formFeedback" class="form-content">
                            <h5 class="title">{{ formFeedback.title }}</h5>
                            <p [innerHTML]="formFeedback.subtitle"></p>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </app-banner-form>
    <div id="wrapper-safe-description">
		<section id="safe-description">
            <div class="benefits">
                <div class="wrapper-container">
                    <div class="container">
                        <div
                            class="safe auto"
                        >
                            <h2>Conheça as nossas coberturas para proteger você e seu carro</h2>
                            <div class="row">
                                <div class="col-6 col-md-3 mb-4 mx-auto mx-md-0" *ngFor="let card of cards">
                                    <app-card classCustom="car" [figure]="card.figure" [title]="card.title" [titleModal]="card.titleModal" [description]="card.description"></app-card>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <app-banner-text [img]="'/assets/images/seguro-auto.jpg'" [title]="'E olha só essas facilidades'" [services]="services"></app-banner-text>
        </section>
    </div>
    <div class="toggle-safes">
        <app-toggle-safe
            [img]="'/assets/images/safe-residence.jpg'"
            [title]="'Proteja também sua casa ou apartamento'"
            [text]="textBannerApartment"
            [link]="'/palmeiras/residencia'"
        ></app-toggle-safe>
        <app-toggle-safe
            [img]="'/assets/images/seguro-vida.jpg'"
            [title]="'Proteja também sua família'"
            [text]="textBannerApartment1"
            [link]="'/palmeiras/vida'"
            [isEven]="true"
        ></app-toggle-safe>
    </div>
</div>

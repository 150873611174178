<div class="control-radio"
     [ngClass]="{'control-error': form.controls[name || ''].invalid &&
			(form.controls[name || ''].touched ||
				form.controls[name || ''].dirty)}"
>
    <label [for]="'r-' + id || name" [formGroup]="form">
        <input
            #element
            type="radio"
            [id]="'r-' + id || name"
            [attr.name]="name"
            [attr.disabled]="disabled ? true : null"
            formControlName="{{ name }}"
            [setValue]="inputModel"
            [value]="value"
            [ngClass]="{'invalid-radio': invalid }"
            (change)="fnChangeModel($event)"
        />
        <div class="checkmark"></div>
        <span>
			{{ labelName }}
            <ng-content></ng-content>
		</span>
    </label>
<!--    <span-->
<!--        *ngIf="-->
<!--			form.controls[name || ''].invalid &&-->
<!--			(form.controls[name || ''].touched ||-->
<!--				form.controls[name || ''].dirty)-->
<!--		"-->
<!--        class="control-error"-->
<!--        [setFormControlError]="{-->
<!--			objError: form.controls[name || ''].errors,-->
<!--			control: labelName-->
<!--		}"-->
<!--    >-->
<!--	</span>-->
</div>

<section id="highlight" [ngClass]="{'invert-colors': invertColors}">
    <div class="highlight">
        <div class="highlight__columns">
            <div class="highlight__columns__item" [ngClass]="{'no-image': img === ''}">
                <div class="highlight__info" [ngClass]="{'no-image-text': img === ''}">
                    <div class="highlight__texts">
                        <h2 *ngIf="title" class="highlight__title" [innerHTML]="title"></h2>
                        <h3 *ngIf="description" class="highlight__subtitle" [innerHTML]="description"></h3>
                    </div>
                    <div *ngIf="img !== ''" style="width: 100%;">
                        <app-image class="highlight__banner" [path]="img" [alt]="'image-banner'" title="banner"></app-image>
                    </div>
                </div>
            </div>
            <div id="quote-form" class="highlight__columns__item other-bg">
                <div class="content">
                    <div class="wrapper-form">
                        <h2>Solicite aqui sua cotação!</h2>
                        <ng-content select="[form]"></ng-content>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<header [ngClass]="{'scroll-on': isScrolled}">
    <div class="wrapper-container">
        <div *ngIf="uri === '/vida-rappiprime' || uri === '/residencia-rappiprime'" class="header-partner">
            <div class="header-partner__logo">
                <a [routerLink]="uri">
                    <figure>
                        <img src="/assets/images/logo.svg" alt="Allianz">
                    </figure>
                </a>
            </div>
            <figure class="header-partner__figure">
                <img src="/assets/images/rappi_logo.png" alt="Allianz">
            </figure>
        </div>
        <div *ngIf="uri === '/palmeiras' || uri === '/palmeiras/auto' || uri === '/palmeiras/residencia' || uri === '/palmeiras/vida'" class="header-partner">
            <div class="header-partner__logo">
                <a [routerLink]="uri">
                    <figure>
                        <img src="/assets/images/logo.svg" alt="Allianz">
                    </figure>
                </a>
            </div>
            <div class="d-flex">
                <figure class="header-partner__figure palmeiras">
                    <img id="img-palmeiras" src="/assets/images/palmeiras_logo.svg" alt="Allianz">
                </figure>
            </div>
        </div>
        <div *ngIf="uri === '/vida' || uri === '/residencia' || uri === '/auto'" class="header-partner">
            <a [routerLink]="uri">
                <figure>
                    <img src="/assets/images/logo.svg" alt="Allianz">
                </figure>
            </a>
        </div>
    </div>
</header>

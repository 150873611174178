<footer>
	<div class="socials">
		<div class="container">
			<div class="row align-items-md-center">
				<div class="col-12 col-md-6 text-center text-md-end pe-md-5">
                    <a href="https://www.allianz.com.br/" target="_blank">
                        <figure>
                            <img src="/assets/images/logo.svg" alt="Allianz">
                        </figure>
                    </a>
				</div>
				<div class="col-12 col-md-6 text-center text-md-start ps-md-5">
					<div class="socials__wrapper">
						<ul class="socials__list">
							<li>
								<a
									href="https://www.instagram.com/allianzbrasil/?hl=pt-br"
									target="_blank"
								>
									<figure>
										<img
											loading = “lazy”
											src="/assets/images/instagram.svg"
											alt="Instagram"
										/>
									</figure>
								</a>
							</li>
							<li>
								<a
									href="https://www.facebook.com/allianzbrasil"
									target="_blank"
								>
									<figure>
										<img
											loading = “lazy”
											src="/assets/images/facebook.svg"
											alt="Facebook"
										/>
									</figure>
								</a>
							</li>
							<li>
								<a
									href="https://www.linkedin.com/company/allianz-seguros-brasil/?viewAsMember=true"
									target="_blank"
								>
									<figure>
										<img
											loading = “lazy”
											src="/assets/images/linkedin.svg"
											alt="Linkedin"
										/>
									</figure>
								</a>
							</li>
							<li>
								<a
									href="https://www.youtube.com/channel/UCqMBrRQRPhCIyzUWmUbfSpw"
									target="_blank"
								>
									<figure>
										<img
											loading = “lazy”
											src="/assets/images/youtube.svg"
											alt="Youtube"
										/>
									</figure>
								</a>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="disclaimer">
		<div class="container">
			<ul class="disclaimer__list">
				<li>
					<a
						href="https://www.allianz.com.br/politica-de-privacidade"
						target="_blank"
					>Política de Privacidade</a
					>
				</li>
				<li>•</li>
				<li>
					<a
						href="https://www.allianz.com.br/politicas-do-sistema.html#cookies"
						target="_blank"
					>Política de Cookies</a
					>
				</li>
				<li>•</li>
				<li>
					<a
						href="https://www.allianz.com/en.html"
						target="_blank"
					>Allianz Global</a
					>
				</li>
			</ul>
			<p *ngIf="uri === '/auto' || uri === '/palmeiras/auto'" class="disclaimer__text">
				Allianz Seguros S.A., CNPJ: 61.573.796/0001-66, Rua Eugênio de Medeiros nº 303, ands. 1-parte, 2 ao 9, 15 e 16, Pinheiros, São Paulo - CEP: 05425-000. Processo SUSEP nº 15414.002216/2004-57. Consulte as Condições Gerais, assim como os planos de assistência 24h, serviços e abrangência do seguro no site <a href="http://www.allianz.com.br" target="_blank">http://www.allianz.com.br</a>. 
				O registro do produto é automático e não representa aprovação ou recomendação por parte da SUSEP. A aceitação do seguro está sujeita a análise do risco.
				Linha Direta Allianz Seguros: <a href="tel:4090 1110" target="_blank">4090 1110</a> (Capitais e Regiões Metropolitanas), <a href="tel:0800 777 7243" target="_blank">0800 777 7243</a> (demais regiões).  SAC (24 horas): <a href="tel:08000 115 215" target="_blank">08000 115 215</a>. Ouvidoria Allianz Seguros: <a href="tel:0800 771 3313" target="_blank">0800 771 3313</a>. Atendimento à pessoa com deficiência auditiva ou de fala: <a href="tel:08000 121 239" target="_blank">08000 121 239</a>.
			</p>

			<p *ngIf=" uri === '/palmeiras'" class="disclaimer__text">
				Allianz Seguros S.A., CNPJ: 61.573.796/0001-66, Rua Eugênio de Medeiros nº 303, ands. 1-parte, 2 ao 9, 15 e 16, Pinheiros, São Paulo - CEP: 05425-000. Processo SUSEP: Residência: 15414.100898/2004-62 - Vida: 15414.001289.2007-74 - Auto: 15414.002216/2004-57. Consulte as Condições Gerais, assim como os planos de assistência 24h, serviços e abrangência do seguro no site <a href="http://www.allianz.com.br" target="_blank">http://www.allianz.com.br</a>. 
				O registro do produto é automático e não representa aprovação ou recomendação por parte da SUSEP. A aceitação do seguro está sujeita a análise do risco. Linha Direta Allianz Seguros: <a href="tel:4090 1110" target="_blank">4090 1110</a> (Capitais e Regiões Metropolitanas), <a href="tel:0800 777 7243" target="_blank">0800 777 7243</a> (demais regiões).  SAC (24 horas): <a href="tel:08000 115 215" target="_blank">08000 115 215</a>. Ouvidoria Allianz Seguros: <a href="tel:0800 771 3313" target="_blank">0800 771 3313</a>. Atendimento à pessoa com deficiência auditiva ou de fala: <a href="tel:08000 121 239" target="_blank">08000 121 239</a>.
			</p>


			<p *ngIf="uri === '/residencia' || uri === '/palmeiras/residencia' || uri === '/residencia-rappiprime'" class="disclaimer__text">
				Allianz Seguros S.A., CNPJ: 61.573.796/0001-66, Rua Eugênio de Medeiros nº 303, ands. 1-parte, 2 ao 9, 15 e 16, Pinheiros, São Paulo - CEP: 05425-000. Processo SUSEP nº 15414.100898/2004-62. Consulte as Condições Gerais, assim como os planos de assistência 24h, serviços e abrangência do seguro no site <a href="http://www.allianz.com.br" target="_blank">http://www.allianz.com.br</a>. 
				O registro do produto é automático e não representa aprovação ou recomendação por parte da SUSEP. A aceitação do seguro está sujeita a análise do risco.
				Linha Direta Allianz Seguros: <a href="tel:4090 1110" target="_blank">4090 1110</a> (Capitais e Regiões Metropolitanas), <a href="tel:0800 777 7243" target="_blank">0800 777 7243</a> (demais regiões).  SAC (24 horas): <a href="tel:08000 115 215" target="_blank">08000 115 215</a>. Ouvidoria Allianz Seguros: <a href="tel:0800 771 3313" target="_blank">0800 771 3313</a>. Atendimento à pessoa com deficiência auditiva ou de fala: <a href="tel:08000 121 239" target="_blank">08000 121 239</a>.
			</p>

			<p *ngIf="uri === '/vida' || uri === '/palmeiras/vida' || uri === '/residencia-rappiprime'" class="disclaimer__text">
				Allianz Seguros S.A., CNPJ: 61.573.796/0001-66, Rua Eugênio de Medeiros nº 303, ands. 1-parte, 2 ao 9, 15 e 16, Pinheiros, São Paulo - CEP: 05425-000. Processo SUSEP nº 15414.001289.2007-74. Consulte as Condições Gerais, assim como os planos de assistência 24h, serviços e abrangência do seguro no site <a href="http://www.allianz.com.br" target="_blank">http://www.allianz.com.br</a>. 
				O registro do produto é automático e não representa aprovação ou recomendação por parte da SUSEP. A aceitação do seguro está sujeita a análise do risco.
				Linha Direta Allianz Seguros: <a href="tel:4090 1110" target="_blank">4090 1110</a> (Capitais e Regiões Metropolitanas), <a href="tel:0800 777 7243" target="_blank">0800 777 7243</a> (demais regiões).  SAC (24 horas): <a href="tel:08000 115 215" target="_blank">08000 115 215</a>. Ouvidoria Allianz Seguros: <a href="tel:0800 771 3313" target="_blank">0800 771 3313</a>. Atendimento à pessoa com deficiência auditiva ou de fala: <a href="tel:08000 121 239" target="_blank">08000 121 239</a>.
			</p>
		</div>
	</div>
</footer>

import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Store } from '@ngrx/store'
import { AppState } from '../../state/app-state'
import { AddressService } from '../../services/address/address.service'
import { FollowizeService } from '../../services/followize/followize.service'
import { ToastrService } from 'ngx-toastr'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { HelpersService } from '../../services/helpers/helpers.service'
import { GenericValidatorService } from '../../services/validators/generic-validator.service'
import { ModalTooltipComponent } from '../../components/modals/modal-tooltip/modal-tooltip.component'
import { HiddenLoading, ShowLoading } from '../../state/loading/loading.action'
import * as moment from 'moment'

@Component({
	selector: 'app-home-rappiprime-insurance',
	templateUrl: './home-rappiprime-insurance.component.html',
	styleUrls: ['./home-rappiprime-insurance.component.scss'],
})
export class HomeRappiPrimeInsuranceComponent implements OnInit {
	form: FormGroup
	hubUtm = ''
	errorZipcode = false
	disabled = true
	step = 1
	formFeedback = {
		title: '',
		subtitle: '',
	}

	public cards = [
		{
			figure: '../assets/images/residence-1.svg',
			title: 'Incêndio, Queda de Raio, Explosão, Queda de Aeronaves e Fumaça',
			titleModal:
				'Incêndio, Queda de Raio, Explosão, Queda de Aeronaves e Fumaça',
			description:
				'Essa é a garantia chamada de <strong>Básica</strong> do seguro, e sua contratação é automática.<br> Para <strong>Incêndio</strong>, a cobertura garante proteção para os danos materiais causados pelo fogo que se propaga com intensidade.<br> Para <strong>Queda de Raio</strong>, a cobertura garante proteção para danos físicos – exceto danos elétricos – causados à residência pelo impacto do raio ocorrido dentro do terreno.<br> Para <strong>Explosão</strong>, a cobertura garante proteção para os danos causados por qualquer tipo de explosão, com exceção de fogos de artifício.<br> Para <strong>Queda de Aeronaves</strong>, a cobertura garante proteção para os danos causados por qualquer tipo de aeronave, bem como qualquer objeto dela ou por ela transportado.<br> Para <strong>Fumaça</strong>, a cobertura garante proteção para danos causados por fumaças geradas a partir de incêndios ou qualquer aparelho instalado na residência.',
		},
		{
			figure: '../assets/images/residence-2.svg',
			title: 'Danos Elétricos',
			titleModal: 'Danos Elétricos',
			description:
				'Cobertura de danos em instalações elétricas, máquinas e equipamentos eletroeletrônicos decorrentes de curtos-circuitos, variações anormais de tensões, descargas elétricas e danos causados pela queda de raio.',
		},
		{
			figure: '../assets/images/residence-3.svg',
			title: 'Roubo e Furto',
			titleModal: 'Roubo e Furto',
			description:
				'Indenização de danos materiais causados aos bens em decorrência desses eventos.',
		},
		{
			figure: '../assets/images/residence-4.svg',
			title: 'Vendaval, Furacão, Ciclone, Tornado e Granizo',
			titleModal: 'Vendaval, Furacão, Ciclone, Tornado e Granizo',
			description:
				'Sabe aquelas horas que você precisa acionar a seguradora, mas fica pensando na franquia que tem que pagar? Pois é, a gente sabe que alguns imprevistos podem atrapalhar nossos projetos, por isso temos planos com franquia reduzida e, até mesmo, com desconto de 100% na franquia.',
		},
		{
			figure: '../assets/images/residence-5.svg',
			title: 'Quebra de Vidros, Mármores e Granitos',
			titleModal: 'Quebra de Vidros, Mármores e Granitos',
			description:
				'Cobre danos causados, por quaisquer acidentes de causa externa, aos vidros, espelhos, mármores e granitos (exceto pisos) instalados de forma fixa no imóvel.',
		},
		{
			figure: '../assets/images/residence-6.svg',
			title: 'Danos por água',
			titleModal: 'Danos por água',
			description:
				'Essa cobertura garante proteção em casos de danos causados por rupturas de tanques e tubulações da sua casa.',
		},
	]
	services = [
		{
			figure: '/assets/images/service-1.png',
			title: `Reparos elétricos e <br />hidráulicos`,
			titleModal: `Reparo elétrico e hidráulico`,
			description: `Reparo hidráulico: você conta com um encanador para resolver problemas
                     como vazamentos de tubulações, torneiras, sifões, e ainda conta com serviço de desentupimento,
                      caça vazamentos e muito mais!<br> Reparo elétrico: em caso de emergência como danos elétricos,
                      curto circuito ou mau funcionamento das tomadas/interruptores enviamos um profissional para
                       reparar e garantir que você não fique sem energia!`,
		},
		{
			figure: '/assets/images/service-2.png',
			title: `Conserto de <br />eletrodomésticos <small class="d-block little-mb">(linha branca)</small>`,
			titleModal: `Conserto de eletrodomésticos (linha branca)`,
			description: `A geladeira estragou? A lavadora de roupas parou de funcionar? Não se preocupe, com nosso seguro você tem profissionais para reparar eletrodomésticos como refrigeradores, fogão, micro-ondas, lavadora e secadora de roupa, e muitos outros.`,
		},
		{
			figure: '/assets/images/service-3.png',
			title: `Pacote de serviços <br />para Bikes`,
			titleModal: `Pacote de serviços para Bikes`,
			description: `Se depender da Allianz, a sua bike nunca vai te deixar a pé! É só
                    ligar e agendar para contar com serviços de montagem, reparo ou troca de pneus, regulagens de
                    freios, lubrificação de correntes, instalação de acessórios e muito mais para a sua bicicleta.`,
		},
		{
			figure: '/assets/images/service-4.png',
			title: `Pacote de <br />Assistência Pets`,
			titleModal: `Pacote de Assistência para Pets`,
			description: `Esse é para pai ou mãe de pet! Você conta com serviços incríveis como
                    aplicação de vacina em domicílio, envio de ração, consulta veterinária ou emergencial, hospedagem
                    e muito mais estão garantidos para o seu cão ou gato.`,
		},
		{
			figure: '/assets/images/service-5.png',
			title: `Check-up lar <br />completo`,
			titleModal: `Check-up lar completo`,
			description: `Quer saber como deixar a sua casa mais segura para o dia a dia? A Allianz te
                    ajudar a fazer um check-up completo na sua casa e analisar pontos de melhorias como instalações elétricas,
                    limpeza de ralos e sifões, troca de tomadas e interruptores e muito mais.`,
		},
		{
			figure: '/assets/images/service-6.png',
			title: `Descarte <br />Responsável`,
			titleModal: `Descarte Responsável`,
			description: `Descartar corretamente também é uma forma de preservar o meio-ambiente e a Allianz te ajuda nessa!
                    Com esse serviço, você tem à sua disposição um prestador que será responsável por coletar e descartar corretamente
                    móveis, eletrodomésticos, eletrônicos e outros, sem agredir o meio ambiente.`,
		},
	]
	textBannerCar = `<strong>Seguro Auto</strong> em até <strong class="red">10x sem juros</strong> no cartão de crédito!<br />
    Ou até <strong class="red">6x sem juros</strong> no <strong class="red">débito em conta!</strong>`
	titleBanner = `Cliente <strong><span class="red">Rappi Prime</span></strong> pode ter o seguro Allianz Residência a partir de <strong><span class="red">R$15,00</span> por mês!</strong>`
	descriptionBanner = `Proteja seu lar pagando em até <strong><span class="red">10x sem juros no débito. </span></strong>Ou, se preferir, pague também no <strong><span class="red">boleto ou cartão.</span></strong>`
	benefits = [
		[
			{
				img: '../assets/images/slide-1-1.png',
				title: 'Sua casa é um dos seus bens mais valiosos!',
				text: `Protegê-la pode custar <strong>até 4 vezes menos</strong> que um seguro de carro.`,
			},
			{
				img: '../assets/images/residence-carousel-slide1-2.png',
				title: 'Contratação sem depreciação',
				text: `Quando ocorrer algum incidente, você receberá a indenização <strong>sem que haja depreciação do valor do bem danificado.</strong>`,
			},
			{
				img: '../assets/images/slide-1-3.svg',
				title: 'Casas de veraneio',
				text: `Já pensou poder proteger também aquele seu outro cantinho de paz? O Allianz Residência tem proteção para a <strong>sua casa habitual e também de veraneio!</strong>`,
			},
			{
				img: '../assets/images/residence-carousel-slide1-4.svg',
				title: 'Residência com escritório',
				text: `Nada mais comum que trabalhar de home office hoje em dia, certo? O Allianz Residência garante <strong>proteção aos seus materiais pessoais de trabalho</strong> que estejam em uso por você.`,
			},
		],
		[
			{
				img: '../assets/images/residence-carousel-slide2-1.svg',
				title: 'Carro na garagem',
				text: `A cobertura de incêndio pode amparar também o seu veículo estacionado na garagem!`,
			},
			{
				img: '../assets/images/residence-carousel-slide2-2.svg',
				title: 'Roubos de bem fora da residência',
				text: `A proteção da sua casa que acompanha você: seu celular, notebook, bike e outros itens vão estar protegidos mesmo fora da sua residência!`,
			},
			{
				img: '../assets/images/residence-carousel-slide2-3.svg',
				title: 'RC Familiar + Danos	morais',
				text: `Acidentes acontecem, certo? O importante é poder contar com o seu seguro residencial para amparar você em acidente contra terceiros, além de ter uma verba destinada para cobrir	danos morais também!`,
			},
			{
				htmlContent: true,
			},
		],
	]

	textBannerApartment1 = `<strong><span class="red">Seguro de Vida</span></strong> a partir de <strong><span class="red">R$19,90</span></strong> por mês.<br /> No <strong>débito automático</strong> ou em até <strong>12x sem juros no boleto</strong>.`

	constructor(
		private store: Store<AppState>,
		private formBuilder: FormBuilder,
		private addressService: AddressService,
		private followizeService: FollowizeService,
		private toast: ToastrService,
		private modal: NgbModal,
		private helpers: HelpersService
	) {
		this.form = this.formBuilder.group({
			safe: ['residencia'],
			teamKey: ['3a0bef766f353d44e01672c11a51f016'],
			hubUtmz: null,
			name: [null, [Validators.required]],
			registrationNumber: [
				null,
				[Validators.required, GenericValidatorService.isValidCpf],
			],
			email: [null, [Validators.required, Validators.email]],
			confirm_email: [null, [Validators.required, Validators.email]],
			phone: [
				null,
				[Validators.required, GenericValidatorService.validatePhone],
			],
			city: [null, [Validators.required]],
			state: [null, [Validators.required]],
			zipCode: [
				null,
				[Validators.required, GenericValidatorService.validateCep],
			],
			message: [null, [Validators.required]],
			safeType: [null, [Validators.required]],
			interests_1: [null],
			interests_2: [null],
			consent: [false, [Validators.requiredTrue]],
		})
		this.watchChangesForm()
	}

	ngOnInit(): void {
		if (window.location.href.split('?').length > 1) {
			this.hubUtm = this.helpers.formatUtmz(window.location.href)
			this.form.controls['hubUtmz'].setValue(this.hubUtm)
		}
	}

	changeEmail(email: string) {
		var re = /\S+@\S+\.\S+/
		if (email) {
			return re.test(email)
		} else {
			return true
		}
	}
	isFieldValid(field: string): boolean {
		const form = this.form
		// @ts-ignore
		return form.get(field).valid
	}
	watchChangesForm(): void {
		const self = this
		const fieldMessage = self.form.get('message')
		const fieldSafe = self.form.get('safeType')
		const fieldInterests1 = self.form.get('interests_1')
		const fieldInterests2 = self.form.get('interests_2')
		const fieldOptin = self.form.get('consent')
		if (fieldMessage) {
			fieldMessage.valueChanges.subscribe((value) => {
				if (value) {
					self.sendGA('Form_Residencia_RappiPrime', 'Contato')
				}
			})
		}
		if (fieldSafe) {
			fieldSafe.valueChanges.subscribe((value) => {
				if (value) {
					self.sendGA('Form_Residencia_RappiPrime', 'Tipo de seguro')
				}
			})
		}
		if (fieldInterests1) {
			fieldInterests1.valueChanges.subscribe((value) => {
				if (value) {
					self.sendGA(
						'Form_Residencia_RappiPrime',
						'Interesse em seguro de Auto'
					)
				}
			})
		}
		if (fieldInterests2) {
			fieldInterests2.valueChanges.subscribe((value) => {
				if (value) {
					self.sendGA(
						'Form_Residencia_RappiPrime',
						'Interesse em seguro de Vida'
					)
				}
			})
		}
		if (fieldOptin) {
			fieldOptin.valueChanges.subscribe((value) => {
				if (value) {
					self.sendGA(
						'Form_Residencia_RappiPrime',
						'Aceite de termos'
					)
				}
			})
		}

		self.form.valueChanges.subscribe((data) => {
			if (
				self.step === 1 &&
				self.isFieldValid('name') &&
				self.isFieldValid('registrationNumber') &&
				self.isFieldValid('email') &&
				self.isFieldValid('name') &&
				self.isFieldValid('confirm_email') &&
				self.form.get('email')?.value ===
					self.form.get('confirm_email')?.value &&
				self.isFieldValid('phone') &&
				self.isFieldValid('message')
			) {
				self.disabled = false
			} else if (
				self.step === 2 &&
				self.isFieldValid('zipCode') &&
				self.isFieldValid('city') &&
				self.isFieldValid('state') &&
				self.isFieldValid('safeType') &&
				self.isFieldValid('consent')
			) {
				self.disabled = false
			} else {
				self.disabled = true
			}
		})
	}
	getAddressByZipCode(value: string): void {
		const self = this
		self.form.controls['city'].setValue('')
		self.form.controls['state'].setValue('')
		if (value.length >= 9) {
			self.errorZipcode = true
		} else {
			self.errorZipcode = false
		}
		if (self.isFieldValid('zipCode')) {
			self.addressService.getAddressByZipCode(value, {
				fnSuccess(data?: any) {
					const city = data.cidade
					const state = data.estado_info.nome
					self.errorZipcode = false
					self.form.controls['city'].setValue(city)
					self.form.controls['state'].setValue(state)
					self.watchChangesForm()
				},
				fnError(error) {
					self.disabled = true
					self.form.controls['city'].setValue('')
					self.form.controls['state'].setValue('')
					value.length >= 9
						? (self.errorZipcode = true)
						: (self.errorZipcode = false)
				},
				fnFinalized() {},
			})
		} else {
			self.disabled = true
			self.form.controls['city'].setValue('')
			self.form.controls['state'].setValue('')
		}
	}
	backStep(): void {
		this.disabled = false
		this.step--
	}
	nextStep(): void {
		this.step++
		if (
			this.step === 1 &&
			this.isFieldValid('name') &&
			this.isFieldValid('registrationNumber') &&
			this.isFieldValid('email') &&
			this.isFieldValid('name') &&
			this.isFieldValid('confirm_email') &&
			this.isFieldValid('phone') &&
			this.isFieldValid('message')
		) {
			this.disabled = false
		} else if (
			this.step === 2 &&
			this.isFieldValid('zipCode') &&
			this.isFieldValid('city') &&
			this.isFieldValid('state') &&
			this.isFieldValid('consent')
		) {
			this.disabled = false
		} else {
			this.disabled = true
		}
	}
	openModal(): void {
		this.modal.open(ModalTooltipComponent, {
			modalDialogClass: 'modal-tooltip',
		})
	}
	onSubmit(): void {
		const self = this
		const data = self.form.value

		if (self.form.valid) {
			self.disabled = true
			self.store.dispatch(ShowLoading())
			const formData = new FormData()

			for (let key in data) {
				if (key === 'interests_1' && data[key]) {
					formData.append(
						`interests_1`,
						'Tenho interesse em seguro de Auto'
					)
				} else if (key === 'interests_2' && data[key]) {
					formData.append(
						`interests_2`,
						'Tenho interesse em seguro de Vida'
					)
				} else if (key === 'consent') {
					formData.append(key, 'on')
				} else {
					formData.append(key, data[key] ? data[key] : '')
				}
			}

			self.followizeService.sendFollowize('', formData, {
				fnSuccess(data?: any) {
					window.pintrk('track', 'lead', {
						lead_type: 'Newsletter',
					})
					window.dataLayer.push({
						event: 'sendlead',
						eventCategory: 'lead',
						eventAction: 'send',
						eventLabel: '',
					})
					window.gtag('event', 'conversion', {
						send_to: 'AW-1031001625/Cet3CP_01-EDEJmsz-sD',
						event_callback: window.location,
					})
					moment.locale('pt-br')
					if (
						(moment().format('dddd') === 'sexta-feira' &&
							parseInt(moment().format('HH')) >= 18) ||
						moment().format('dddd') === 'sábado' ||
						moment().format('dddd') === 'domingo' ||
						(moment().format('dddd') === 'Friday' &&
							parseInt(moment().format('HH')) >= 18) ||
						moment().format('dddd') === 'Saturday' ||
						moment().format('dddd') === 'Sunday'
					) {
						self.formFeedback = {
							title: 'Agradecemos o seu interesse!',
							subtitle:
								'Nosso horário de atendimento é de segunda a sexta, de 09h às 18h. Em breve entraremos em contato com você',
						}
					} else if (
						(moment().format('dddd') === 'segunda-feira' &&
							(parseInt(moment().format('HH')) >= 18 ||
								parseInt(moment().format('HH')) >= 9)) ||
						(moment().format('dddd') === 'terça-feira' &&
							(parseInt(moment().format('HH')) >= 18 ||
								parseInt(moment().format('HH')) >= 9)) ||
						(moment().format('dddd') === 'quarta-feira' &&
							(parseInt(moment().format('HH')) >= 18 ||
								parseInt(moment().format('HH')) >= 9)) ||
						(moment().format('dddd') === 'quinta-feira' &&
							(parseInt(moment().format('HH')) >= 18 ||
								parseInt(moment().format('HH')) >= 9)) ||
						(moment().format('dddd') === 'Monday' &&
							(parseInt(moment().format('HH')) >= 18 ||
								parseInt(moment().format('HH')) >= 9)) ||
						(moment().format('dddd') === 'Tuesday' &&
							(parseInt(moment().format('HH')) >= 18 ||
								parseInt(moment().format('HH')) >= 9)) ||
						(moment().format('dddd') === 'Wednesday' &&
							(parseInt(moment().format('HH')) >= 18 ||
								parseInt(moment().format('HH')) >= 9)) ||
						(moment().format('dddd') === 'Thursday' &&
							(parseInt(moment().format('HH')) >= 18 ||
								parseInt(moment().format('HH')) >= 9))
					) {
						self.formFeedback = {
							title: 'Agradecemos o seu interesse!',
							subtitle: `Um de nossos corretores entrará em contato com você!`,
						}
					} else {
						self.formFeedback = {
							title: 'Agradecemos o seu interesse!',
							subtitle: `Nosso horário de atendimento é de segunda a sexta, de 09h às 18h. Em breve entraremos em contato com você!`,
						}
					}
				},
				fnError(error) {
					self.formFeedback = {
						title: 'Ops!',
						subtitle: `Ocorreu um erro inesperado. <br />Tente novamente!`,
					}
				},
				fnFinalized() {
					self.step = 3
					self.form.reset()
					self.store.dispatch(HiddenLoading())
				},
			})
		} else {
			self.step++
			self.disabled = true
		}
	}

	changeRadio(event: any): any {
		if (event) {
			this.form.get('name')?.markAsTouched()
			this.form.get('registrationNumber')?.markAsTouched()
			this.form.get('email')?.markAsTouched()
			this.form.get('confirm_email')?.markAsTouched()
			this.form.get('phone')?.markAsTouched()
		}
	}

	sendGA(category: string, label: string): void {
		window.ga('send', {
			hitType: 'event',
			eventAction: 'clicou',
			eventCategory: category,
			eventLabel: label,
		})
	}
}

<div class="modal-content">
    <button
        type="button"
        class="close"
        (click)="close()"
    >
        <span aria-hidden="true">&times;</span>
    </button>
    <div class="modal-body">
        <h4 class="modal-description mb-0">
            Digite primeiro o modelo do seu veículo e ao encontrá-lo, selecione a opção correspondente. Se o seu veículo não retornar na busca significa que no momento não estamos preparados para protegê-lo. Agradecemos o seu interesse na Allianz e torcemos para que em breve seja possível ter você aqui com a gente!
        </h4>
    </div>
</div>

<div class="palmeiras">
    <div class="toggle-safes">
        <app-toggle-safe
            [img]="'/assets/images/palmeiras_feminino.jpg'"
            [idElement]="'palmeirasFeminino'"
            [isEven]="true"
            [isOther]="true"
        >
            <div class="text-area">
                <p class="text-title">O Maior <strong class="red">Campeão do Brasil</strong> merece a melhor proteção para as suas <span class="red">conquistas!</span></p>
                <p class="text-description">Você, palmeirense, conta com os benefícios facilidades que só a Allianz Seguros oferece para proteger sua família alviverde, seu lar e seu carro de qualquer imprevisto que possa entrar em campo.</p>
                <p class="text-footer red"> Seja do time Allianz e aproveite <strong>todos os benefícios!</strong></p>
            </div>
        </app-toggle-safe>
    </div>
    <section class="pt-5">
        <h2 class="title-tab">Confira os seguros disponíveis para você!</h2>
        <app-tabs-rounded>
            <app-tab-rounded *ngFor="let tab of tabs" [tabTitle]="tab.title" [active]="tab.active">
                <div
                *ngIf="tab.content === 'auto'"
                class="safe"
                >
                    <div class="know-more-wrapper-tab">
                        <p class="know-more-wrapper-tab-title" style="font-size: 45px"><strong>Proteja seu carro</strong> em até <strong class="red">10x sem juros!</strong></p>
                        <p class="know-more-wrapper-tab-subtitle">No <strong class="red">cartão de crédito</strong></p>
                        <p class="know-more-wrapper-tab-text">Seguro para o seu carro contra colisão, roubo, furto e alagamentos. Além disso, você também conta com diferenciais como motorista amigo e a facilidade de acessar informações do seu seguro pelo App.</p>
                        <a [routerLink]="'/palmeiras/auto'" class="buttonChanger">
                            Quero uma cotação
                        </a>
                        <p class="know-more-wrapper-tab-legaltext" style="font-size: 12px">Algumas coberturas e serviços possuem restrições e limites diferenciados. Para saber todos os detalhes e conhecer a lista completa consulte o Manual do Segurado/Condições Gerais.</p>
                    </div>
                    <app-image class="know-more-wrapper-img" [path]="'/assets/images/safe-auto.jpg'" [title]="'Proteja também seu carro'" [alt]="'image-safe'"></app-image>
                </div>
                <div
                *ngIf="tab.content === 'residence'"
                class="safe"
                >
                    <div class="know-more-wrapper-tab">
                        <p class="know-more-wrapper-tab-title" style="max-width: 423px;">Garanta <strong>todos os cuidados </strong>para a <strong class="red">sua casa,</strong></p>
                        <p class="know-more-wrapper-tab-subtitle">a partir de <strong class="red"> R$15,00 por mês.</strong></p>
                        <p class="know-more-wrapper-tab-text" style="margin-bottom: 5px;">Mais de 70 serviços disponíveis para dar aquela mãozinha no seu dia a dia como encanador, eletricista e assistência pet (cães e gatos).</p>
                        <p class="know-more-wrapper-tab-text">Além das Coberturas que fazem a diferença na hora que você precisa: danos elétricos ou por água, incêndio, roubo, furto e muito mais. </p>
                        <a [routerLink]="'/palmeiras/residencia'" class="buttonChanger">
                            Quero uma cotação
                        </a>
                        <p class="know-more-wrapper-tab-legaltext" style="font-size: 12px">Algumas coberturas e serviços possuem restrições, carência e limites diferenciados. Para saber todos os detalhes e conhecer a lista completa consulte o Manual do Segurado/Condições Gerais.</p>
                    </div>
                    <app-image class="know-more-wrapper-img" style="height: 100%" [path]="'/assets/images/safe-residence.jpg'" [title]="''" [alt]="'image-safe'"></app-image>
                </div>
                <div
                *ngIf="tab.content === 'life'"
                class="safe"
                >
                    <div class="know-more-wrapper-tab">
                        <p class="know-more-wrapper-tab-title" style="font-size: 40px">A partir de <strong class="red">R$ 19,90 por mês</strong> você aproveita <strong>o hoje </strong> e <strong class="red">protege o amanhã</strong></p>
                        <p class="know-more-wrapper-tab-text">Coberturas, serviços e assistências que cuidam de você hoje, como assistência nutricional ou desconto em medicamentos, e garantem um amanhã mais tranquilo para quem você ama.</p>
                        <a [routerLink]="'/palmeiras/vida'" class="buttonChanger">
                            Quero uma cotação
                        </a>

                    </div>
                    <app-image class="know-more-wrapper-img" [path]="'/assets/images/seguro-vida.jpg'" [title]="''" [alt]="'image-safe'"></app-image>
                </div>
            </app-tab-rounded>
        </app-tabs-rounded>
        <div class="avanti-palestra">
            <div class="avanti-palestra-texts">
                <p class="avanti-palestra-texts-title">Avanti Palestra!</p>
                <p style="max-width: 475px;">Quem torce pelo <strong class="red">MAIOR</strong>, está sempre pronto para viver <strong class="red">o melhor!</strong></p>
            </div>
        </div>
    </section>
</div>

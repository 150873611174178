import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { NotFoundComponent } from './pages/not-found/not-found.component'
import { CarInsuranceComponent } from './pages/car-insurance/car-insurance.component'
import { LifeInsuranceComponent } from './pages/life-insurance/life-insurance.component'
import { HomeInsuranceComponent } from './pages/home-insurance/home-insurance.component'
import { RappiprimeLifeComponent } from './pages/rappiprime-life/rappiprime-life.component'
import { HomeRappiPrimeInsuranceComponent } from './pages/home-rappiprime-insurance/home-rappiprime-insurance.component'
import { PalmeirasInsuranceComponent } from './pages/palmeiras-insurance/palmeiras-insurance.component'
import { PalmeirasCarInsuranceComponent } from './pages/palmeiras-car-insurance/palmeiras-car-insurance.component'
import { PalmeirasHomeInsuranceComponent } from './pages/palmeiras-home-insurance/palmeiras-home-insurance.component'
import { PalmeirasLifeInsuranceComponent } from './pages/palmeiras-life-insurance/palmeiras-life-insurance.component'

const routes: Routes = [
	{
		path: '',
		redirectTo: '/auto',
		pathMatch: 'full',
	},
	{
		path: 'auto',
		component: CarInsuranceComponent,
		data: { title: 'Seguro Allianz - Auto! Faça sua cotação' },
	},
	{
		path: 'vida',
		component: LifeInsuranceComponent,
		data: { title: 'Seguro Allianz - Vida! Faça sua cotação' },
	},
	{
		path: 'residencia',
		component: HomeInsuranceComponent,
		data: { title: 'Seguro Allianz - Residência! Faça sua cotação' },
	},
	{
		path: 'residencia-rappiprime',
		component: HomeRappiPrimeInsuranceComponent,
		data: { title: 'Seguro Allianz - Faça sua cotação' },
	},
	{
		path: 'vida-rappiprime',
		component: RappiprimeLifeComponent,
		data: { title: 'Seguro Allianz - Vida RappiPrime! Faça sua cotação' },
	},
	{
		path: 'palmeiras',
		component: PalmeirasInsuranceComponent,
		data: { title: 'Seguro Allianz - Palmeiras! Faça sua cotação' }
	},
	{
		path: 'palmeiras/auto',
		component: PalmeirasCarInsuranceComponent,
		data: { title: 'Seguro Allianz - Palmeiras! Faça sua cotação' }
	},
	{
		path: 'palmeiras/residencia',
		component: PalmeirasHomeInsuranceComponent,
		data: { title: 'Seguro Allianz - Palmeiras! Faça sua cotação' }
	},
	{
		path: 'palmeiras/vida',
		component: PalmeirasLifeInsuranceComponent,
		data: { title: 'Seguro Allianz - Palmeiras! Faça sua cotação' }
	},
	{
		path: '404',
		component: NotFoundComponent,
		data: { title: 'Página não encontrada' },
	},
	{
		path: '**',
		redirectTo: '/404',
		data: { title: 'Página não encontrada' },
	},
]

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			scrollPositionRestoration: 'enabled',
		}),
	],
	exports: [RouterModule],
})
export class AppRoutingModule {}

<div class="section-banner-text py-5">
    <div class="wrapper-container">
        <div class="container">
            <h2 class="title-section">{{ title }}</h2>
            <div class="row align-items-md-center mb-5">
                <div
                    *ngIf="img"
                    class="col-12 col-md-6 col-lg-5 ps-md-4 ps-lg-5"
                >
                    <app-image [path]="img" [title]="title" [alt]="'image-banner'"></app-image>
                </div>
                <div
                    *ngIf="services"
                    class="col-12 col-md-6 col-lg-7 pe-md-5 ps-lg-5"
                >
                    <div *ngFor="let service of services" class="service">
                        <app-image [path]="service.img" [title]="service.title" [alt]="'image-banner'"></app-image>
                        <div class="text">
                            <h3 class="description__title" [innerHTML]="service.title"></h3>
                            <p class="description__subtitle" [innerHTML]="service.description"></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div [id]="idElement" class="know-more-content" [ngClass]="{'is-even': isEven, 'is-other': isOther}">
    <app-image [path]="img" [title]="title" [alt]="'image-safe'"></app-image>
    <div class="know-more-wrapper" #ref><ng-content ></ng-content></div>
    <div class="know-more-wrapper" *ngIf=" ! ref.children.length">
        <h2>{{title}}</h2>
        <p [innerHTML]="text"></p>
        <a [routerLink]="link" class="buttonChanger">
            Vem saber mais
        </a>
    </div>
</div>

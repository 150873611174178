import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core'

@Component({
	selector: 'app-toggle-safe',
	templateUrl: './toggle-safe.component.html',
	styleUrls: ['./toggle-safe.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class ToggleSafeComponent implements OnInit {
	@Input() title?: string = ''
	@Input() text?: string = ''
	@Input() img: string = ''
	@Input() link?: string = ''
	@Input() isEven: boolean = false
	@Input() isOther: boolean = false
	@Input() idElement: string = 'idPadrao'

	constructor() {}

	ngOnInit(): void {}
}

import {
	Component,
	ContentChildren,
	EventEmitter,
	OnInit,
	Output,
	QueryList,
} from '@angular/core'
import { TabRoundedComponent } from './tab-rounded/tab-rounded.component'

@Component({
	selector: 'app-tabs-rounded',
	templateUrl: './tabs-rounded.component.html',
	styleUrls: ['./tabs-rounded.component.scss'],
})
export class TabsRoundedComponent implements OnInit {
	@ContentChildren(TabRoundedComponent) tabs?: QueryList<TabRoundedComponent>
	@Output() tabOpenEvent = new EventEmitter<string>()

	ngAfterContentInit() {
		let activeTabs = this.tabs?.filter((tab: { active: any }) => tab.active)

		if (activeTabs?.length === 0) {
			this.selectTab(this.tabs?.first)
		}
	}

	selectTab(tab: any) {
		this.tabs
			?.toArray()
			.forEach((tab: { active: boolean }) => (tab.active = false))
		tab.active = true
		this.tabOpenEvent.emit(tab.title)
	}

	ngOnInit(): void {}
}

import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'

@Component({
	selector: 'app-modal-benefit',
	templateUrl: './modal-benefit.component.html',
	styleUrls: ['./modal-benefit.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class ModalBenefitComponent implements OnInit {
	@Input() figure?: string = ''
	@Input() titleModal?: string = ''
	@Input() description?: string = ''

	constructor(public activeModal: NgbActiveModal) {}

	ngOnInit(): void {}
	close() {
		this.activeModal.close()
	}
	scrollToSection(id: string) {
		this.close()
		setTimeout(function () {
			const el = document.getElementById(id) || null
			if (el) {
				window.scrollTo({
					top: window.scrollY + el.getBoundingClientRect().top - 100,
					behavior: 'smooth',
				})
			}
		}, 300)
	}
}

import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core'

@Component({
	selector: 'app-carousel-benefits',
	templateUrl: './carousel-benefits.component.html',
	styleUrls: ['./carousel-benefits.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class CarouselBenefitsComponent implements OnInit {

	@Input() data: Array<any> = []

	slideConfig = {"slidesToShow": 1, "slidesToScroll": 1, "dots": true, "infinite": false};

	constructor() {}

	ngOnInit(): void {
	}

	cotacao () {
		window.scrollTo(0, 0)
	}
}

import { Component, OnInit } from '@angular/core'
import { Location } from '@angular/common'
import { Event, NavigationEnd, Router } from '@angular/router'

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
	isScrolled: boolean = false
	uri: any

	constructor(public router: Router, private location: Location) {}

	ngOnInit(): void {
		this.scrollHeader()
		window.addEventListener('scroll', this.scrollHeader.bind(this))

		this.router.events.subscribe((event: Event) => {
			if (event instanceof NavigationEnd) {
				this.uri = this.location.path().split('?')[0]
			}
		})
	}
	scrollHeader(): void {
		const scrollTop = window.scrollY || document.documentElement.scrollTop
		this.isScrolled = scrollTop > 50
	}
}

import { Component, OnInit } from '@angular/core'
import { Event, NavigationEnd, Router } from '@angular/router'
import { Location } from '@angular/common'

@Component({
	selector: 'app-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
	uri: any

	constructor(public router: Router, private location: Location) {}

	ngOnInit(): void {
		this.router.events.subscribe((event: Event) => {
			if (event instanceof NavigationEnd) {
				this.uri = this.location.path()
			}
		})
	}
}

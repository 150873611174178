import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { FormGroup } from '@angular/forms'

@Component({
	selector: 'app-radio',
	templateUrl: './radio.component.html',
	styleUrls: ['./radio.component.scss'],
})
export class RadioComponent implements OnInit {
	@Input() form: FormGroup = new FormGroup(Input())
	@Input() id?: string
	@Input() name: string | number | null = ''
	@Input() value?: boolean | string
	@Input() disabled?: boolean
	@Input() invalid?: boolean = false
	@Input() labelName?: string
	@Input() inputModel: any
	@Input() changeModel?: Function

	constructor() {}

	ngOnInit(): void {}

	fnChangeModel(event: any): void {
		if (this.changeModel) this.changeModel(event)
	}
}

<button
    class="button-card"
    [attr.data-modalTitle]="titleModal"
    [attr.data-modalDescription]="description"
    [ngClass]="classCustom"
    (click)="openModal()"
>
    <app-image [path]="figure" [title]="title" [alt]="'image-card'"></app-image>
    <h3 *ngIf="title" [innerHTML]="title"></h3>
    <span>ver mais +</span>
</button>

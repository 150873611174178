import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Store } from '@ngrx/store'
import { AppState } from '../../state/app-state'
import { AddressService } from '../../services/address/address.service'
import { FollowizeService } from '../../services/followize/followize.service'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { GenericValidatorService } from '../../services/validators/generic-validator.service'
import { ModalTooltipComponent } from '../../components/modals/modal-tooltip/modal-tooltip.component'
import { HiddenLoading, ShowLoading } from '../../state/loading/loading.action'
import { HelpersService } from '../../services/helpers/helpers.service'
import { VehicleService } from 'src/app/services/vehicle/vehicle.service'
import { ModalModelComponent } from 'src/app/components/modals/modal-model/modal-model.component'
import * as moment from 'moment'

export interface moc {
	value: string
	label: string
}

@Component({
	selector: 'app-palmeiras-car-insurance',
	templateUrl: './palmeiras-car-insurance.component.html',
	styleUrls: ['./palmeiras-car-insurance.component.scss'],
})
export class PalmeirasCarInsuranceComponent implements OnInit {
	public time: any = 0
	modelSelected = false
	fullRequest = false
	form: FormGroup
	hubUtm = ''
	errorZipcode = false
	disabled = true
	step = 1
	formFeedback = {
		title: '',
		subtitle: '',
	}
	modelos: Array<moc> = []
	years: any[] = []
	types: any[] = [
		{
			value: 'Automóvel',
			label: 'Carro',
		},
		{
			value: 'Moto',
			label: 'Moto',
		},
		{
			value: 'Caminhão',
			label: 'Caminhão',
		},
	]
	nationalities: any[] = [
		{
			value: 'Nacional',
			label: 'Nacional',
		},
		{
			value: 'Importado',
			label: 'Importado',
		},
	]
	vehicles = [
		{
			value: 'Moto',
			label: 'Moto',
		},
		{
			value: 'Auto',
			label: 'Auto',
		},
		{
			value: 'Caminhao',
			label: 'Caminhão',
		},
	]

	cards = [
		{
			figure: '/assets/images/auto-1.svg',
			title: 'Roubo, Furto <br /> ou Perda Total',
			titleModal: 'Roubo, Furto ou Perda Total',
			description:
				'Caso algum destes incidentes aconteçam,<br> você recebe a indenização de acordo com a modalidade contratada.',
		},
		{
			figure: '/assets/images/auto-2.svg',
			title: 'Colisão',
			titleModal: 'Colisão',
			description:
				'Reparamos o seu veículo e você pode contar com um valor de franquia reduzido.',
		},
		{
			figure: '/assets/images/auto-6.svg',
			title: 'Até 30 dias de<br />carro reserva',
			titleModal: 'Até 30 dias de carro reserva',
			description:
				'Para aquelas horas que você precisa acionar alguma cobertura do seu seguro e fica sem o seu carro temporariamente. Fique tranquilo, o nosso seguro conta com essa ajudinha.',
		},
		{
			figure: '/assets/images/auto-7.svg',
			title: 'Franquia Reduzida',
			titleModal: 'Franquia Reduzida',
			description:
				'Sabe aquelas horas que você precisa acionar a seguradora, mas fica pensando na franquia que tem que pagar? Pois é, a gente sabe que alguns imprevistos podem atrapalhar nossos projetos, por isso temos planos com franquia reduzida e, até mesmo, com desconto de 100% na franquia.',
		},
		{
			figure: '/assets/images/auto-3.svg',
			title: 'Assistência <br />Guincho com 200 <br />Km ou Km livre',
			titleModal: 'Assistência Guincho com 200, 500 ou KM ilimitado.',
			description:
				'Problemas acontecem e você pode precisar de um guincho, chaveiro, troca de pneus, carga de bateria, reboque em caso de pane seca ou outro serviço.',
		},
		{
			figure: '/assets/images/auto-4.svg',
			title: 'Alagamento e outros <br />eventos da natureza',
			titleModal: 'Alagamento e outros eventos da natureza',
			description:
				'A gente não pode controlar o tempo, nem as chuvas, alagamentos, granizo e outros eventos naturais, por isso, cobrimos tudo isso e muito mais!',
		},
		{
			figure: '/assets/images/auto-5.svg',
			title: 'Proteção a <br />Terceiros',
			titleModal: 'Proteção a Terceiros',
			description:
				'Indenização para danos causados a terceiros, sejam danos materiais, corporais ou morais.',
		},
		{
			figure: '/assets/images/auto-8.svg',
			title: 'Acidentes Pessoais<br />de Passageiros',
			titleModal: 'Acidentes Pessoais de Passageiros',
			description:
				'Acidentes podem acontecer, ninguém gosta ou quer pensar nisso, mas caso algum passageiro do veículo venha a se ferir (parcial ou invalidez permanente) ou falecer, uma ajuda extra pode ser importante.',
		},
		{
			figure: '/assets/images/auto-9.svg',
			title: 'Proteção para<br />Vidros',
			titleModal: 'Proteção para Vidros',
			description:
				'Assistência de vidros que cobre para-brisa, vidros laterais, traseiro, retrovisores, lanternas e faróis de LED e Xênon.',
		},
		{
			figure: '/assets/images/auto-11.svg',
			title: 'Carta Verde',
			titleModal: 'Carta Verde',
			description:
				'Se você é um daqueles que cruzam fronteiras e mora nos estados do Paraná, Santa Catarina, Rio Grande do Sul ou Mato Grosso do Sul, o seu seguro Allianz Auto conta automaticamente com a cobertura de Carta Verde, um documento exigido pelas autoridades para ingresso em países do MERCOSUL e Chile.',
		},
		{
			figure: '/assets/images/auto-10.svg',
			title: 'Assistência<br />Residencial',
			titleModal: 'Assistência Residencial',
			description:
				'E se um imprevisto acontecer em casa, como um problema elétrico ou hidráulico? Sabia que seu seguro de automóvel também te ajuda com isso?',
		},
	]
	services = [
		{
			img: '/assets/images/service-auto-1.svg',
			title: `App Allianz Cliente Auto. Precisou?<br /> Tá na mão!`,
			description: `Consulte as principais informações da sua apólice, parcelas, boletos e muito mais!`,
		},
		{
			img: '/assets/images/service-auto-2.svg',
			title: `Assistência pelo WhatsApp`,
			description: `Precisou de guincho ou socorro mecânico? É só<br /> chamar a gente pelo WhatsApp.`,
		},
		{
			img: '/assets/images/service-auto-3.svg',
			title: `Clube de descontos Allianz`,
			description: `Cliente Allianz tem descontos imperdíveis em<br /> diversas lojas parceiras, como Americanas,<br /> Centauro, Sephora, Petz e muitas outras lojas.`,
		},
	]

	textBannerApartment = `A partir de <strong><span class="red">R$15,00</span> por mês!</strong><br />Você pode pagar em até <strong>10x sem juros no débito e conta</strong>, e proteger seu
    lar, seja alugado ou próprio..`
	textBannerApartment1 = `<strong><span class="red">Seguro de Vida</span></strong> a partir de <strong><span class="red">R$19,90</span></strong> por mês.<br /> No <strong>débito automático</strong> ou em até <strong>12x sem juros no boleto</strong>.`

	titleBanner = `<strong>Seguro Auto</strong> em até <strong class="red">10x sem juros</strong> no cartão de crédito!`
	descriptionBanner = `Ou até <strong class="red">6x sem juros</strong> no <strong class="red">débito em conta!</strong>`

	showLoading = false

	constructor(
		private store: Store<AppState>,
		private formBuilder: FormBuilder,
		private addressService: AddressService,
		private followizeService: FollowizeService,
		private modal: NgbModal,
		private helpers: HelpersService,
		private veihcleService: VehicleService
	) {
		this.form = this.formBuilder.group({
			safe: ['auto'],
			teamKey: ['45d08902864e3fc49559193470afd0f0'],
			hubUtmz: null,
			name: [null, [Validators.required]],
			registrationNumber: [
				null,
				[Validators.required, GenericValidatorService.isValidCpf],
			],
			email: [null, [Validators.required, Validators.email]],
			confirm_email: [null, [Validators.required, Validators.email]],
			phone: [
				null,
				[Validators.required, GenericValidatorService.validatePhone],
			],
			city: [null, [Validators.required]],
			state: [null, [Validators.required]],
			zipCode: [
				null,
				[Validators.required, GenericValidatorService.validateCep],
			],
			message: [null, [Validators.required]],
			safeType: [null, [Validators.required]],
			interest6Title: [null, [Validators.required]],
			interest5Title: [null],
			consent: [false, [Validators.requiredTrue]],
			interest1Title: [null, [Validators.required]],
			nationality: [null, [Validators.required]],
			interest3Title: [null, [Validators.required]],
		})
		this.watchChangesForm()
	}

	ngOnInit(): void {
		if (window.location.href.split('?').length > 1) {
			this.hubUtm = this.helpers.formatUtmz(window.location.href)
			this.form.controls['hubUtmz'].setValue(this.hubUtm)
		}
	}

	changeEmail(email: string) {
		var re = /\S+@\S+\.\S+/
		if (email) {
			return re.test(email)
		} else {
			return true
		}
	}
	isFieldValid(field: string): boolean {
		const form = this.form
		// @ts-ignore
		return form.get(field).valid
	}
	watchChangesForm(): void {
		const self = this
		const fieldMessage = self.form.get('message')
		const fieldType = self.form.get('interest1Title')
		const fieldNationality = self.form.get('nationality')
		const fieldYear = self.form.get('interest6Title')
		const fieldSafe = self.form.get('safeType')
		const fieldOptin = self.form.get('consent')
		if (fieldMessage) {
			fieldMessage.valueChanges.subscribe((value) => {
				if (value) {
					self.sendGA('Form_Auto_Palmeiras', 'Contato')
				}
			})
		}
		if (fieldType) {
			fieldType.valueChanges.subscribe((value) => {
				if (value) {
					self.sendGA('Form_Auto_Palmeiras', 'Tipo de veículo')
				}
			})
		}
		if (fieldNationality) {
			fieldNationality.valueChanges.subscribe((value) => {
				if (value) {
					self.sendGA('Form_Auto_Palmeiras', 'Nacionalidade')
				}
			})
		}
		if (fieldYear) {
			fieldYear.valueChanges.subscribe((value) => {
				if (value) {
					self.sendGA('Form_Auto_Palmeiras', 'Ano')
				}
			})
		}
		if (fieldSafe) {
			fieldSafe.valueChanges.subscribe((value) => {
				if (value) {
					self.sendGA('Form_Auto_Palmeiras', 'Tipo de seguro')
				}
			})
		}
		if (fieldOptin) {
			fieldOptin.valueChanges.subscribe((value) => {
				if (value) {
					self.sendGA('Form_Auto_Palmeiras', 'Aceite de termos')
				}
			})
		}

		self.form.valueChanges.subscribe((data) => {
			if (
				self.step === 1 &&
				self.isFieldValid('name') &&
				self.isFieldValid('registrationNumber') &&
				self.isFieldValid('email') &&
				self.isFieldValid('name') &&
				self.isFieldValid('confirm_email') &&
				self.form.get('email')?.value ===
					self.form.get('confirm_email')?.value &&
				self.isFieldValid('phone') &&
				self.isFieldValid('message')
			) {
				self.disabled = false
			} else if (
				self.step === 2 &&
				self.isFieldValid('zipCode') &&
				self.isFieldValid('city') &&
				self.isFieldValid('state')
			) {
				self.disabled = false
			} else if (
				self.step === 3 &&
				self.isFieldValid('interest1Title') &&
				self.isFieldValid('nationality') &&
				self.isFieldValid('interest6Title') &&
				self.isFieldValid('interest3Title') &&
				self.isFieldValid('safeType') &&
				self.modelSelected &&
				self.isFieldValid('consent')
			) {
				if (
					self.form.get('interest6Title')?.value === '0' &&
					!self.form.get('interest5Title')?.value
				) {
					self.disabled = false
				} else if (self.form.get('interest5Title')?.value) {
					self.disabled = false
				} else {
					self.disabled = true
				}
			} else {
				self.disabled = true
			}
		})
	}
	getAddressByZipCode(value: string): void {
		const self = this
		self.form.controls['city'].setValue('')
		self.form.controls['state'].setValue('')
		if (value.length >= 9) {
			self.errorZipcode = true
		} else {
			self.errorZipcode = false
		}
		if (self.isFieldValid('zipCode')) {
			self.addressService.getAddressByZipCode(value, {
				fnSuccess(data?: any) {
					const city = data.cidade
					const state = data.estado_info.nome
					self.errorZipcode = false
					self.form.controls['city'].setValue(city)
					self.form.controls['state'].setValue(state)
					self.disabled = false
				},
				fnError(error) {
					self.disabled = true
					self.form.controls['city'].setValue('')
					self.form.controls['state'].setValue('')
					value.length >= 9
						? (self.errorZipcode = true)
						: (self.errorZipcode = false)
				},
				fnFinalized() {},
			})
		} else {
			self.disabled = true
			self.form.controls['city'].setValue('')
			self.form.controls['state'].setValue('')
		}
	}
	backStep(): void {
		this.disabled = false
		this.step--
	}
	nextStep(): void {
		this.step++
		if (
			this.step === 1 &&
			this.isFieldValid('name') &&
			this.isFieldValid('registrationNumber') &&
			this.isFieldValid('email') &&
			this.isFieldValid('confirm_email') &&
			this.isFieldValid('phone') &&
			this.isFieldValid('message')
		) {
			this.disabled = false
		} else if (
			this.step === 2 &&
			this.isFieldValid('zipCode') &&
			this.isFieldValid('city') &&
			this.isFieldValid('state')
		) {
			this.disabled = false
		} else if (
			this.step === 3 &&
			this.isFieldValid('interest1Title') &&
			this.isFieldValid('nationality') &&
			this.isFieldValid('interest6Title') &&
			this.isFieldValid('interest3Title') &&
			this.isFieldValid('safeType') &&
			this.isFieldValid('consent') &&
			this.modelSelected
		) {
			if (
				this.form.get('interest6Title')?.value === '0' &&
				!this.form.get('interest5Title')?.value
			) {
				this.disabled = false
			} else if (this.form.get('interest5Title')?.value) {
				this.disabled = false
			} else {
				this.disabled = true
			}
		} else {
			this.disabled = true
		}
	}
	openModal(): void {
		this.modal.open(ModalTooltipComponent, {
			modalDialogClass: 'modal-tooltip',
		})
	}
	openModalModel(): void {
		this.modal.open(ModalModelComponent, {
			modalDialogClass: 'modal-tooltip',
		})
	}
	onSubmit(): void {
		const self = this
		const data = self.form.value

		if (self.form.valid && self.step === 3) {
			self.disabled = true
			self.store.dispatch(ShowLoading())
			const formData = new FormData()

			for (let key in data) {
				if (key === 'consent') {
					formData.append(key, 'on')
				} else {
					formData.append(key, data[key] ? data[key] : '')
				}
			}

			self.followizeService.sendFollowize('', formData, {
				fnSuccess(data?: any) {
					window.pintrk('track', 'lead', {
						lead_type: 'Newsletter',
					})
					window.dataLayer.push({
						event: 'sendlead',
						eventCategory: 'lead',
						eventAction: 'send',
						eventLabel: '',
					})
					window.gtag('event', 'conversion', {
						send_to: 'AW-1031001625/En43CLyhiuEDEJmsz-sD',
						event_callback: window.location,
					})

					moment.locale('pt-br')
					if (
						(moment().format('dddd') === 'sexta-feira' &&
							parseInt(moment().format('HH')) >= 18) ||
						moment().format('dddd') === 'sábado' ||
						moment().format('dddd') === 'domingo' ||
						(moment().format('dddd') === 'Friday' &&
							parseInt(moment().format('HH')) >= 18) ||
						moment().format('dddd') === 'Saturday' ||
						moment().format('dddd') === 'Sunday'
					) {
						self.formFeedback = {
							title: 'Agradecemos o seu interesse!',
							subtitle:
								'Nosso horário de atendimento é de segunda a sexta, de 09h às 18h. Em breve entraremos em contato com você',
						}
					} else if (
						(moment().format('dddd') === 'segunda-feira' &&
							(parseInt(moment().format('HH')) >= 18 ||
								parseInt(moment().format('HH')) >= 9)) ||
						(moment().format('dddd') === 'terça-feira' &&
							(parseInt(moment().format('HH')) >= 18 ||
								parseInt(moment().format('HH')) >= 9)) ||
						(moment().format('dddd') === 'quarta-feira' &&
							(parseInt(moment().format('HH')) >= 18 ||
								parseInt(moment().format('HH')) >= 9)) ||
						(moment().format('dddd') === 'quinta-feira' &&
							(parseInt(moment().format('HH')) >= 18 ||
								parseInt(moment().format('HH')) >= 9)) ||
						(moment().format('dddd') === 'Monday' &&
							(parseInt(moment().format('HH')) >= 18 ||
								parseInt(moment().format('HH')) >= 9)) ||
						(moment().format('dddd') === 'Tuesday' &&
							(parseInt(moment().format('HH')) >= 18 ||
								parseInt(moment().format('HH')) >= 9)) ||
						(moment().format('dddd') === 'Wednesday' &&
							(parseInt(moment().format('HH')) >= 18 ||
								parseInt(moment().format('HH')) >= 9)) ||
						(moment().format('dddd') === 'Thursday' &&
							(parseInt(moment().format('HH')) >= 18 ||
								parseInt(moment().format('HH')) >= 9))
					) {
						self.formFeedback = {
							title: 'Agradecemos o seu interesse!',
							subtitle: `Um de nossos corretores entrará em contato com você!`,
						}
					} else {
						self.formFeedback = {
							title: 'Agradecemos o seu interesse!',
							subtitle: `Nosso horário de atendimento é de segunda a sexta, de 09h às 18h. Em breve entraremos em contato com você!`,
						}
					}
				},
				fnError(error) {
					self.formFeedback = {
						title: 'Ops!',
						subtitle: `Ocorreu um erro inesperado. <br />Tente novamente!`,
					}
					self.fullRequest = true
				},
				fnFinalized() {
					self.step = 4
					self.form.reset()
					self.store.dispatch(HiddenLoading())
				},
			})
		} else {
			self.step++
			this.watchChangesForm()
		}
	}

	getYears() {
		const self = this
		const data = self.form.value
		self.years = []
		self.modelos = []
		this.form.get('interest6Title')?.setValue('')
		this.form.get('interest3Title')?.setValue('')
		this.showLoading = false
		self.disabled = true
		this.modelSelected = false
		if (
			this.form.get('interest1Title')?.value &&
			this.form.get('nationality')?.value
		) {
			const payload = {
				type: this.form.get('interest1Title')?.value,
				origin: this.form.get('nationality')?.value,
			}
			self.veihcleService.getYears(payload, {
				fnSuccess(data?: any) {
					if (data.response === 'success') {
						data.years.forEach((item: number) => {
							const obj = {
								value: item,
								label: item === 0 ? '0km' : item.toString(),
							}
							self.years.push(obj)
						})
					} else {
						data.years.forEach((item: number) => {
							const obj = {
								value: item,
								label: item === 0 ? '0km' : item.toString(),
							}
							self.years.push(obj)
						})
					}
				},
				fnError(error) {
					self.formFeedback = {
						title: 'Ops!',
						subtitle: `Ocorreu um erro inesperado. <br />Tente novamente!`,
					}
				},
			})
		}
	}

	getModel(type: string) {
		const self = this
		self.modelos = []
		self.modelSelected = false
		self.disabled = true
		if (
			this.form.get('interest1Title')?.value &&
			this.form.get('nationality')?.value &&
			this.form.get('interest6Title')?.value
		) {
			const payload = {
				type: this.form.get('interest1Title')?.value,
				origin: this.form.get('nationality')?.value,
				year: this.form.get('interest6Title')?.value,
				model: type,
			}
			this.veihcleService.getModel(payload, {
				fnSuccess(data?: any) {
					if (data.response === 'success') {
						const noData = {
							value: '00',
							label: 'Modelo não encontrado ou sem cobertura…',
						}
						if (data.length === 0) {
							self.modelos.push(noData)
							self.form.get('interest3Title')?.setValue('')
						} else {
							data.forEach((item: any) => {
								const obj = {
									value: item.model,
									label: `${item.model} - ${item.version}`,
								}
								self.modelos.push(obj)
							})
						}
						self.showLoading = false
					} else {
						const noData = {
							value: '00',
							label: 'Modelo não encontrado ou sem cobertura…',
						}
						if (data.length === 0) {
							self.modelos.push(noData)
							self.form.get('interest3Title')?.setValue('')
						} else {
							data.forEach((item: any) => {
								const obj = {
									value: item.model,
									label: `${item.model} - ${item.version}`,
								}
								self.modelos.push(obj)
							})
						}
						self.showLoading = false
					}
				},
				fnError(error) {
					self.formFeedback = {
						title: 'Ops!',
						subtitle: `Ocorreu um erro inesperado. <br />Tente novamente!`,
					}
					self.showLoading = false
				},
			})
		}
	}

	onSearch(event: any) {
		this.showLoading = true
		this.disabled = true
		this.modelSelected = false
		clearTimeout(this.time)
		if (event) {
			this.time = setTimeout(() => {
				this.getModel(event)
			}, 2400)
		}
	}

	onSelect(event: any) {
		if (event.value && event.value !== '') {
			this.modelSelected = true
		} else {
			this.modelSelected = false
		}
		this.form.get('interest3Title')?.setValue(event.value)
	}

	changeRadio(event: any): any {
		if (event) {
			this.form.get('name')?.markAsTouched()
			this.form.get('registrationNumber')?.markAsTouched()
			this.form.get('email')?.markAsTouched()
			this.form.get('confirm_email')?.markAsTouched()
			this.form.get('phone')?.markAsTouched()
		}
	}

	sendGA(category: string, label: string): void {
		window.ga('send', {
			hitType: 'event',
			eventAction: 'clicou',
			eventCategory: category,
			eventLabel: label,
		})
	}
}

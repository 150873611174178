import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { ModalBenefitComponent } from '../modals/modal-benefit/modal-benefit.component'

@Component({
	selector: 'app-card',
	templateUrl: './card.component.html',
	styleUrls: ['./card.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class CardComponent implements OnInit {
	@Input() classCustom: string = ''
	@Input() figure?: string = ''
	@Input() title?: string = ''
	@Input() titleModal?: string = ''
	@Input() description?: string = ''

	constructor(public route: ActivatedRoute, private modal: NgbModal) {}

	ngOnInit(): void {}
	openModal(): void {
		const modalRef = this.modal.open(ModalBenefitComponent, {
			modalDialogClass: 'modal-benefit',
		})
		modalRef.componentInstance.figure = this.figure
		modalRef.componentInstance.titleModal = this.titleModal
		modalRef.componentInstance.description = this.description
	}
}

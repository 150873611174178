import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { GenericValidatorService } from '../../services/validators/generic-validator.service'
import { Store } from '@ngrx/store'
import { AppState } from '../../state/app-state'
import { HiddenLoading, ShowLoading } from '../../state/loading/loading.action'
import { ToastrService } from 'ngx-toastr'
import { AddressService } from '../../services/address/address.service'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { ModalTooltipComponent } from '../../components/modals/modal-tooltip/modal-tooltip.component'
import { FollowizeService } from '../../services/followize/followize.service'
import { HelpersService } from '../../services/helpers/helpers.service'
import * as moment from 'moment'

@Component({
	selector: 'app-palmeiras-life-insurance',
	templateUrl: './palmeiras-life-insurance.component.html',
	styleUrls: ['./palmeiras-life-insurance.component.scss'],
})
export class PalmeirasLifeInsuranceComponent implements OnInit {
	form: FormGroup
	hubUtm = ''
	errorZipcode = false
	disabled = true
	step = 1
	formFeedback = {
		title: '',
		subtitle: '',
	}

	titleBanner = `Allianz Vida. Para <strong>você aproveitar o hoje </strong><strong class="red">e proteger o amanhã!</strong>`
	descriptionBanner = `A partir de R$ 19,90 por mês! <br /> E você ainda pode pagar <span class="red">em até 12x sem juros, no boleto ou débito em conta</span>.`
	tabs = [
		{
			title: 'Para cuidar do hoje',
			active: true,
			content: 'today',
		},
		{
			title: 'Para cuidar do amanhã',
			active: false,
			content: 'tomorrow',
		},
	]
	cards = {
		today: {
			toppings: [
				{
					title: 'Cobertura de doenças graves',
					titleModal: 'Cobertura de doenças graves',
					description: `Pagamento de indenização em caso de diagnóstico de uma das doenças cobertas.<br /><br /> <strong>Plano Exclusivo I:</strong><br /> Câncer,  Acidente Vascular Cerebral, Infarto Agudo do Miocárdio, Transplante de Órgãos, Insuficiência Renal Crônica.<br /><br /> <strong>Plano Exclusivo II:</strong><br /> Câncer, Acidente Vascular Cerebral, Infarto Agudo do Miocárdio, Transplante de Órgãos, Insuficiência Renal Crônica, Alzheimer, Diagnóstico de Surdez, Cirurgia de Revascularização Miocárdica com Implante De Ponte(s) Vascular(es) nas Artérias Coronárias (Bypass), Diagnóstico de Cegueira, Embolia Pulmonar ou Tromboembolismo (Tep), Esclerose Múltipla, Paralisia de Membros,  Doença de Parkinson, Perda Total da Fala.`,
					img: '/assets/images/vida-1.svg',
				},
				{
					title: 'Diagnóstico de Câncer',
					titleModal: 'Diagnóstico de Câncer',
					description: `Cobertura que garante o pagamento de indenização caso você venha a apresentar o diagnóstico de qualquer das doenças  cobertas. <br /><br /> <strong>Para mulheres:</strong><br /> Câncer de Colo do Útero, Câncer do Ovário, Câncer de Mama. <br /><br /> <strong>Para homens:</strong><br /> Câncer de Próstata.<br /><br /> <span class='small'>* A cobertura está presente no pacote Master</span>`,
					img: '/assets/images/vida-2.svg',
				},
				{
					title: 'Cobertura para invalidez permanente total ou parcial por acidente',
					titleModal:
						'Cobertura para invalidez permanente total ou parcial por acidente',
					description: `Pagamento de indenização caso aconteça uma lesão física que cause a perda ou redução definitiva, total ou parcial, de um membro ou órgão, por conta de algum acidente coberto.`,
					img: '/assets/images/vida-3.svg',
				},
				{
					title: 'Invalidez funcional permanente total por doença',
					titleModal:
						'Invalidez funcional permanente total por doença',
					description: `Pagamento antecipado de indenização contratada para a cobertura básica de morte, em casos de Invalidez Funcional Total e Permanente causada por doenças.`,
					img: '/assets/images/vida-4.svg',
				},
			],
			assists: [
				{
					title: 'Assistência Nutricional',
					titleModal: 'Assistência Nutricional*',
					description: `Com nossa assistência nutricional você poderá contar com Atendimento/ Consulta por telefone para:<br /><br /> Cálculo do IMC (Índice de Massa Corporal) e anamnese específica para determinar antecedentes nutricionais, (patológicos, histórico familiar e culturais); Orientações para clientes com doenças crônicas, transtornos alimentares e gestantes;<br /><br /> <strong>Saúde da gestante:</strong> Orientações para alimentação saudável, dicas e orientações para evitar náuseas, diabetes e hipertensão gestacional.<br /> <strong>Saúde da Criança:</strong> orientações para os pais, baseado em dicas e receitas de alimentação saudável, alergias alimentares e culinária infantil para seus filhos;<br /> <strong>Saúde da Mulher:</strong> Aspectos nutricionais relacionados ao ciclo menstrual, tensão pré-menstrual e menopausa.<br /><br /> <strong>E MUITO MAIS!</strong> <br /><br /> <span class='small'>*A Assistência Nutricional faz parte dos pacotes Master , Exclusivo 1 e Exclusivo 2.</span>`,
					img: '/assets/images/vida-5.svg',
				},
				{
					title: 'Segunda opinião médica internacional',
					titleModal: 'Segunda opinião médica internacional*',
					description: `Você poderá contar nos casos de uma doença complexa coberta, o rápido acesso a um segundo parecer médico dado por um especialista de renome internacional.<br /><br /> <span class='small'>* A segunda opinião médica internacional  está presente nos pacotes Plus, Master, Exclusivo 1 e Exclusivo 2.</span>`,
					img: '/assets/images/vida-6.svg',
				},
				{
					title: 'Desconto em medicamentos',
					titleModal: 'Desconto em medicamentos*',
					description: `Com uma rede que conta com mais de 9.000 (*) farmácias, em todo o território nacional, você poderá contar com uma moderna estrutura tecnológica e organizacional para a obtenção de descontos de até 59% em medicamentos.<br /><br /> <span class='small'>*Essa assistência somente está disponível nos pacotes Master, Exclusivo 1 e Exclusivo 2.</span>`,
					img: '/assets/images/vida-7.svg',
				},
			],
		},
		tomorrow: {
			toppings: [
				{
					title: 'Cobertura por morte natural ou acidental',
					titleModal: 'Cobertura por morte natural ou acidental',
					description: `<strong>Pagamento de indenização</strong> por morte natural, inclusive para eventos decorrentes da Covid-19.`,
					img: '/assets/images/vida-8.svg',
				},
				{
					title: 'Indenização especial por morte acidental',
					titleModal: 'Indenização especial por morte acidental',
					description: `Pagamento de indenização por morte causada por acidente pessoal que esteja coberto.`,
					img: '/assets/images/vida-9.svg',
				},
			],
			assists: [
				{
					title: `Assistência Funeral Ampliada<br /> R$ 7.000,00`,
					titleModal: `Assistência Funeral Ampliada<br /> R$ 7.000,00*`,
					description: `Oferece serviços funerários em caso de falecimento do segurado titular, cônjuge, pais e sogros, todos até 70 anos na data da inclusão na apólice e filhos conforme regra do  Imposto de Renda.<br /><br /> <span class='small'>* A Assistência Funeral Ampliada está presente nos pacotes Plus, Master, Exclusivo 1 e Exclusivo 2.</span>`,
					img: '/assets/images/vida-10.svg',
				},
				{
					title: `Assistência Funeral Familiar<br /> R$ 5.000,00`,
					titleModal: `Assistência Funeral Familiar<br /> R$ 5.000,00*`,
					description: `Oferece serviços funerários em caso de falecimento do segurado titular, cônjuge, até 70 anos na data da inclusão na apólice e filhos conforme regra do Imposto de Renda. <br /><br /> <span class='small'>* A Assistência Funeral Familiar está presente nos pacotes Simplificado e Básico.</span>`,
					img: '/assets/images/vida-11.svg',
				},
			],
		},
	}
	services = [
		{
			img: '/assets/images/service-vida-1.svg',
			title: `Facilidade no pagamento`,
			description: `Pagamento em até 12x sem juros no boleto ou no débito automático.`,
		},
		{
			img: '/assets/images/service-vida-2.svg',
			title: `Serviços Digitais para simplificar o dia a dia`,
			description: `Na Allianz os clientes contam com canais digitais, como Chat e WhatsApp, para consultar informações e serviços do seguro.`,
		},
		{
			img: '/assets/images/service-vida-3.svg',
			title: `Clube de Descontos Allianz`,
			description: `Cliente Allianz tem descontos imperdíveis em diversas lojas parceiras, como Americanas, Centauro, Sephora, Petz e muitas outras.`,
		},
	]
	textSafeResidence = `A partir de <strong><span class="red">R$15,00</span> por mês!</strong><br /> E você ainda pode pagar em até <strong>10x sem juros no débito em conta</strong>.`
	textSafeAuto = `<strong>Seguro Auto</strong> em até <strong class="red">10x sem juros</strong> no cartão de crédito!<br />
    Ou até <strong class="red">6x sem juros</strong> no <strong class="red">débito em conta!</strong>`

	constructor(
		private store: Store<AppState>,
		private formBuilder: FormBuilder,
		private addressService: AddressService,
		private followizeService: FollowizeService,
		private toast: ToastrService,
		private modal: NgbModal,
		private helpers: HelpersService
	) {
		this.form = this.formBuilder.group({
			safe: ['vida'],
			teamKey: ['868f068ffeddd6b2fbfd3886317ac460'],
			hubUtmz: null,
			name: [null, [Validators.required]],
			registrationNumber: [
				null,
				[Validators.required, GenericValidatorService.isValidCpf],
			],
			email: [null, [Validators.required, Validators.email]],
			confirm_email: [null, [Validators.required, Validators.email]],
			phone: [
				null,
				[Validators.required, GenericValidatorService.validatePhone],
			],
			city: [null, [Validators.required]],
			state: [null, [Validators.required]],
			zipCode: [
				null,
				[Validators.required, GenericValidatorService.validateCep],
			],
			message: [null, [Validators.required]],
			safeType: [null, [Validators.required]],
			interests_1: [null],
			interests_2: [null],
			consent: [false, [Validators.requiredTrue]],
		})
		this.watchChangesForm()
	}

	ngOnInit(): void {
		if (window.location.href.split('?').length > 1) {
			this.hubUtm = this.helpers.formatUtmz(window.location.href)
			this.form.controls['hubUtmz'].setValue(this.hubUtm)
		}
	}
	isFieldValid(field: string): boolean {
		const form = this.form
		// @ts-ignore
		return form.get(field).valid
	}
	watchChangesForm(): void {
		const self = this
		const fieldMessage = self.form.get('message')
		const fieldSafe = self.form.get('safeType')
		const fieldInterests1 = self.form.get('interests_1')
		const fieldInterests2 = self.form.get('interests_2')
		const fieldOptin = self.form.get('consent')
		if (fieldMessage) {
			fieldMessage.valueChanges.subscribe((value) => {
				if (value) {
					self.sendGA('Form_Vida_Palmeiras', 'Contato')
				}
			})
		}
		if (fieldSafe) {
			fieldSafe.valueChanges.subscribe((value) => {
				if (value) {
					self.sendGA('Form_Vida_Palmeiras', 'Tipo de seguro')
				}
			})
		}
		if (fieldInterests1) {
			fieldInterests1.valueChanges.subscribe((value) => {
				if (value) {
					self.sendGA(
						'Form_Vida_Palmeiras',
						'Interesse em seguro de Auto'
					)
				}
			})
		}
		if (fieldInterests2) {
			fieldInterests2.valueChanges.subscribe((value) => {
				if (value) {
					self.sendGA(
						'Form_Vida_Palmeiras',
						'Interesse em seguro de Residência'
					)
				}
			})
		}
		if (fieldOptin) {
			fieldOptin.valueChanges.subscribe((value) => {
				if (value) {
					self.sendGA('Form_Vida_Palmeiras', 'Aceite de termos')
				}
			})
		}

		self.form.valueChanges.subscribe((data) => {
			if (
				self.step === 1 &&
				self.isFieldValid('name') &&
				self.isFieldValid('registrationNumber') &&
				self.isFieldValid('email') &&
				self.isFieldValid('name') &&
				self.isFieldValid('confirm_email') &&
				self.form.get('email')?.value ===
					self.form.get('confirm_email')?.value &&
				self.isFieldValid('phone') &&
				self.isFieldValid('message')
			) {
				self.disabled = false
			} else if (
				self.step === 2 &&
				self.isFieldValid('zipCode') &&
				self.isFieldValid('city') &&
				self.isFieldValid('state') &&
				self.isFieldValid('safeType') &&
				self.isFieldValid('consent')
			) {
				self.disabled = false
			} else {
				self.disabled = true
			}
		})
	}
	getAddressByZipCode(value: string): void {
		const self = this
		self.form.controls['city'].setValue('')
		self.form.controls['state'].setValue('')
		if (value.length >= 9) {
			self.errorZipcode = true
		} else {
			self.errorZipcode = false
		}
		if (self.isFieldValid('zipCode')) {
			self.addressService.getAddressByZipCode(value, {
				fnSuccess(data?: any) {
					const city = data.cidade
					const state = data.estado_info.nome
					self.errorZipcode = false
					self.form.controls['city'].setValue(city)
					self.form.controls['state'].setValue(state)
					self.watchChangesForm()
				},
				fnError(error) {
					self.disabled = true
					self.form.controls['city'].setValue('')
					self.form.controls['state'].setValue('')
					value.length >= 9
						? (self.errorZipcode = true)
						: (self.errorZipcode = false)
				},
				fnFinalized() {},
			})
		} else {
			self.disabled = true
			self.form.controls['city'].setValue('')
			self.form.controls['state'].setValue('')
		}
	}
	backStep(): void {
		this.disabled = false
		this.step--
	}
	nextStep(): void {
		this.step++
		if (
			this.step === 1 &&
			this.isFieldValid('name') &&
			this.isFieldValid('registrationNumber') &&
			this.isFieldValid('email') &&
			this.isFieldValid('name') &&
			this.isFieldValid('confirm_email') &&
			this.form.get('email')?.value ===
				this.form.get('confirm_email')?.value &&
			this.isFieldValid('phone') &&
			this.isFieldValid('message')
		) {
			this.disabled = false
		} else if (
			this.step === 2 &&
			this.isFieldValid('zipCode') &&
			this.isFieldValid('city') &&
			this.isFieldValid('state') &&
			this.isFieldValid('safeType') &&
			this.isFieldValid('consent')
		) {
			this.disabled = false
		} else {
			this.disabled = true
		}
	}

	changeEmail(email: string) {
		var re = /\S+@\S+\.\S+/
		if (email) {
			return re.test(email)
		} else {
			return true
		}
	}
	openModal(): void {
		this.modal.open(ModalTooltipComponent, {
			modalDialogClass: 'modal-tooltip',
		})
	}
	onSubmit(): void {
		const self = this
		const data = self.form.value

		if (self.form.valid) {
			self.disabled = true
			self.store.dispatch(ShowLoading())
			const formData = new FormData()

			for (let key in data) {
				if (key === 'interests_1' && data[key]) {
					formData.append(
						`interests_1`,
						'Tenho interesse em seguro de Auto'
					)
				} else if (key === 'interests_2' && data[key]) {
					formData.append(
						`interests_2`,
						'Tenho interesse em seguro de Residência'
					)
				} else if (key === 'consent') {
					formData.append(key, 'on')
				} else {
					formData.append(key, data[key] ? data[key] : '')
				}
			}

			self.followizeService.sendFollowize('', formData, {
				fnSuccess(data?: any) {
					window.pintrk('track', 'lead', {
						lead_type: 'Newsletter',
					})
					window.dataLayer.push({
						event: 'sendlead',
						eventCategory: 'lead',
						eventAction: 'send',
						eventLabel: '',
					})
					window.gtag('event', 'conversion', {
						send_to: 'AW-1031001625/s4wDCK_p1-EDEJmsz-sD',
						event_callback: window.location,
					})

					moment.locale('pt-br')
					if (
						(moment().format('dddd') === 'sexta-feira' &&
							parseInt(moment().format('HH')) >= 18) ||
						moment().format('dddd') === 'sábado' ||
						moment().format('dddd') === 'domingo' ||
						(moment().format('dddd') === 'Friday' &&
							parseInt(moment().format('HH')) >= 18) ||
						moment().format('dddd') === 'Saturday' ||
						moment().format('dddd') === 'Sunday'
					) {
						self.formFeedback = {
							title: 'Agradecemos o seu interesse!',
							subtitle:
								'Nosso horário de atendimento é de segunda a sexta, de 09h às 18h. Em breve entraremos em contato com você',
						}
					} else if (
						(moment().format('dddd') === 'segunda-feira' &&
							(parseInt(moment().format('HH')) >= 18 ||
								parseInt(moment().format('HH')) >= 9)) ||
						(moment().format('dddd') === 'terça-feira' &&
							(parseInt(moment().format('HH')) >= 18 ||
								parseInt(moment().format('HH')) >= 9)) ||
						(moment().format('dddd') === 'quarta-feira' &&
							(parseInt(moment().format('HH')) >= 18 ||
								parseInt(moment().format('HH')) >= 9)) ||
						(moment().format('dddd') === 'quinta-feira' &&
							(parseInt(moment().format('HH')) >= 18 ||
								parseInt(moment().format('HH')) >= 9)) ||
						(moment().format('dddd') === 'Monday' &&
							(parseInt(moment().format('HH')) >= 18 ||
								parseInt(moment().format('HH')) >= 9)) ||
						(moment().format('dddd') === 'Tuesday' &&
							(parseInt(moment().format('HH')) >= 18 ||
								parseInt(moment().format('HH')) >= 9)) ||
						(moment().format('dddd') === 'Wednesday' &&
							(parseInt(moment().format('HH')) >= 18 ||
								parseInt(moment().format('HH')) >= 9)) ||
						(moment().format('dddd') === 'Thursday' &&
							(parseInt(moment().format('HH')) >= 18 ||
								parseInt(moment().format('HH')) >= 9))
					) {
						self.formFeedback = {
							title: 'Agradecemos o seu interesse!',
							subtitle: `Um de nossos corretores entrará em contato com você!`,
						}
					} else {
						self.formFeedback = {
							title: 'Agradecemos o seu interesse!',
							subtitle: `Nosso horário de atendimento é de segunda a sexta, de 09h às 18h. Em breve entraremos em contato com você!`,
						}
					}
				},
				fnError(error) {
					self.formFeedback = {
						title: 'Ops!',
						subtitle: `Ocorreu um erro inesperado. <br />Tente novamente!`,
					}
				},
				fnFinalized() {
					self.step = 3
					self.form.reset()
					self.store.dispatch(HiddenLoading())
				},
			})
		} else {
			self.step++
			self.disabled = true
		}
	}

	changeRadio(event: any): any {
		if (event) {
			this.form.get('name')?.markAsTouched()
			this.form.get('registrationNumber')?.markAsTouched()
			this.form.get('email')?.markAsTouched()
			this.form.get('confirm_email')?.markAsTouched()
			this.form.get('phone')?.markAsTouched()
		}
	}

	sendGA(category: string, label: string): void {
		window.ga('send', {
			hitType: 'event',
			eventAction: 'clicou',
			eventCategory: category,
			eventLabel: label,
		})
	}
}

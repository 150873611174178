<div class="modal-content">
    <button
        type="button"
        class="close"
        (click)="close()"
    >
        <span aria-hidden="true">&times;</span>
    </button>
    <div class="modal-body">
        <h4 class="modal-description mb-0">
            Entendo que a contratação de seguro é realizada com a
            intermediação de um corretor de seguros independente. Ao
            clicar em “enviar”, estou ciente de que, para receber o
            cotação solicitada, a Allianz disponibilizará meus dados
            para um corretor devidamente habilitado, que irá me
            fornecer a cotação e poderá entrar em contato para obter
            e fornecer informações complementares. Estou ciente,
            ainda, que a Allianz manterá meus dados em sua base para
            envio de informações sobre produtos e serviços que
            possam ser do meu interesse.
        </h4>
    </div>
</div>

<ul class="nav nav-tabs">
	<li
		*ngFor="let tab of tabs"
		(click)="selectTab(tab)"
		[class.active]="tab.active"
	>
		<button type="button" class="btn btn-primary">{{ tab.title }}</button>
	</li>
</ul>
<ng-content></ng-content>

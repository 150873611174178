<div class="modal-content">
    <button
        type="button"
        class="close"
        (click)="close()"
    >
        <span aria-hidden="true">&times;</span>
    </button>
    <div class="modal-body">
        <app-image *ngIf="figure" class="modal-figure" [path]="figure" [alt]="'image-modal'"></app-image>
        <h2 *ngIf="titleModal" class="modal-title" [innerHTML]="titleModal"></h2>
        <h4
            *ngIf="description"
            class="modal-description"
            [innerHTML]="description"
        ></h4>
        <button class="modal-button" [attr.data-benefit]="titleModal" (click)="scrollToSection('quote-form')">
            quero uma cotação
        </button>
        <p class="modal-info">
            Processo SUSEP nº.15414.100898/2004-62. Consulte mais informações no site <a href="http://www.allianz.com.br" target="_blank">http://www.allianz.com.br</a>. Algumas coberturas, serviços e assistências possuem restrições, carência e limites diferenciados. Para saber todos os detalhes e conhecer a lista completa consulte o Manual do Segurado/Condições Gerais. A aceitação da proposta do seguro está sujeita à análise do risco. O registro do produto é automático e não representa aprovação ou recomendação por parte da SUSEP.
        </p>
    </div>
</div>

import { NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'
import { StoreModule } from '@ngrx/store'
import { LazyLoadImageModule } from 'ng-lazyload-image'
import { NgxMaskModule } from 'ngx-mask'
import { CommonModule } from '@angular/common'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { ToastrModule } from 'ngx-toastr'
import { SlickCarouselModule } from 'ngx-slick-carousel'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'

import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { stateReducers } from './state/reducers'

import { NotFoundComponent } from './pages/not-found/not-found.component'
import { InputComponent } from './components/form/input/input.component'
import { SelectComponent } from './components/form/select/select.component'
import { CheckboxComponent } from './components/form/checkbox/checkbox.component'
import { RadioComponent } from './components/form/radio/radio.component'
import { TableListComponent } from './components/table-list/table-list.component'
import { ImageComponent } from './components/image/image.component'
import { TabComponent } from './components/tabs/tab/tab.component'
import { TabsComponent } from './components/tabs/tabs.component'
import { AccordionComponent } from './components/accordion/accordion.component'
import { ModalDefaultComponent } from './components/modals/modal-default/modal-default.component'
import { SetValueDirective } from './directives/set-value.directive'
import { SetFormControlErrorDirective } from './directives/set-form-control-error.directive'
import { AuthInterceptorService } from './services/auth/auth-interceptor.service'
import { LoadingGlobalComponent } from './components/loading/loading-global/loading-global.component'
import { LoadingLocalComponent } from './components/loading/loading-local/loading-local.component'
import { LoadingProgressComponent } from './components/loading/loading-progress/loading-progress.component'
import { CheckboxCustomComponent } from './components/form/checkbox-custom/checkbox-custom.component'
import { HeaderComponent } from './components/header/header.component'
import { FooterComponent } from './components/footer/footer.component'
import { CardComponent } from './components/card/card.component'
import { CarInsuranceComponent } from './pages/car-insurance/car-insurance.component'
import { LifeInsuranceComponent } from './pages/life-insurance/life-insurance.component'
import { BannerFormComponent } from './components/banner-form/banner-form.component'
import { ModalBenefitComponent } from './components/modals/modal-benefit/modal-benefit.component'
import { BannerTextComponent } from './components/banner-text/banner-text.component'
import { ModalTooltipComponent } from './components/modals/modal-tooltip/modal-tooltip.component'
import { HomeInsuranceComponent } from './pages/home-insurance/home-insurance.component'
import { CarouselBenefitsComponent } from './components/carousel-benefits/carousel-benefits.component'
import { ToggleSafeComponent } from './components/toggle-safe/toggle-safe.component'
import { RappiprimeLifeComponent } from './pages/rappiprime-life/rappiprime-life.component'
import { HomeRappiPrimeInsuranceComponent } from './pages/home-rappiprime-insurance/home-rappiprime-insurance.component'
import { SelectAutocompleteComponent } from './components/form/select-autocomplete/select-autocomplete.component'
import { PalmeirasInsuranceComponent } from './pages/palmeiras-insurance/palmeiras-insurance.component'
import { TabsRoundedComponent } from './components/tabs-rounded/tabs-rounded.component'
import { TabRoundedComponent } from './components/tabs-rounded/tab-rounded/tab-rounded.component'
import { PalmeirasCarInsuranceComponent } from './pages/palmeiras-car-insurance/palmeiras-car-insurance.component'
import { PalmeirasHomeInsuranceComponent } from './pages/palmeiras-home-insurance/palmeiras-home-insurance.component'
import { PalmeirasLifeInsuranceComponent } from './pages/palmeiras-life-insurance/palmeiras-life-insurance.component'
import { ModalModelComponent } from './components/modals/modal-model/modal-model.component'

@NgModule({
	declarations: [
		AppComponent,
		NotFoundComponent,
		InputComponent,
		SelectComponent,
		SelectAutocompleteComponent,
		CheckboxComponent,
		TableListComponent,
		ImageComponent,
		TabComponent,
		TabsComponent,
		AccordionComponent,
		SetValueDirective,
		SetFormControlErrorDirective,
		ModalDefaultComponent,
		LoadingGlobalComponent,
		LoadingLocalComponent,
		LoadingProgressComponent,
		CheckboxCustomComponent,
		HeaderComponent,
		FooterComponent,
		CardComponent,
		CarInsuranceComponent,
		LifeInsuranceComponent,
		BannerFormComponent,
		ModalBenefitComponent,
		BannerTextComponent,
		RadioComponent,
		ModalTooltipComponent,
		HomeInsuranceComponent,
		CarouselBenefitsComponent,
		ToggleSafeComponent,
		RappiprimeLifeComponent,
		HomeRappiPrimeInsuranceComponent,
		PalmeirasInsuranceComponent,
		TabsRoundedComponent,
		TabRoundedComponent,
		PalmeirasCarInsuranceComponent,
		PalmeirasHomeInsuranceComponent,
		PalmeirasLifeInsuranceComponent,
		ModalModelComponent
	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		HttpClientModule,
		LazyLoadImageModule,
		StoreModule.forRoot({ ...stateReducers }, {}),
		NgbModule,
		FormsModule,
		ReactiveFormsModule,
		NgxMaskModule.forRoot(),
		CommonModule,
		BrowserAnimationsModule,
		ToastrModule.forRoot({
			positionClass: 'toast-top-right',
		}),
		SlickCarouselModule,
	],
	providers: [
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AuthInterceptorService,
			multi: true,
		},
	],
	bootstrap: [AppComponent],
})
export class AppModule {}

<div class="life">
    <app-banner-form [title]="titleBanner" [description]="descriptionBanner" img="/assets/images/vida.jpg">
        <div form>
            <form action='#'
                  autocomplete='off'
                  (ngSubmit)='onSubmit()'
                  [formGroup]='form'
                  class="formCustom">
                <input type="hidden" name="hubUtmz" id="hubUtmz" value="" />
                <div class="progress-wrap">
                    <ul class="checkout-bar">
                        <li class="progressbar-dots" [ngClass]="{'active': step === 1, 'complete': step > 1}">
                            <div class="line-progress-bar" [ngClass]="{'active': step === 1, 'complete': step > 1}"></div>
                            <span>1</span>
                        </li>
                        <li class="progressbar-dots" [ngClass]="{'active': step === 2, 'complete': step > 2}">
                            <div class="line-progress-bar" [ngClass]="{'active': step === 2, 'complete': step > 2}"></div>
                            <span>2</span>
                        </li>
                        <li class="progressbar-dots last" [ngClass]="{'active': step === 3, 'complete': step >= 3}">
                            <span>3</span>
                        </li>
                    </ul>
                </div>
                <div class="wrapper-steps">
                    <div class="wrapper-steps__step" [ngClass]="step === 1 ? 'show' : 'hide'" id="step1">
                        <div class="form-content">
                            <div class="field">
                                <app-input
                                    id='name'
                                    name='name'
                                    placeholder='Nome'
                                    labelName='Nome'
                                    [form]='form'
                                    (emmitBlur)='sendGA("Form_Vida", "Nome")'
                                ></app-input>
                            </div>
                            <div class="field">
                                <app-input
                                    id='registrationNumber'
                                    name='registrationNumber'
                                    placeholder='CPF'
                                    labelName='CPF'
                                    [form]='form'
                                    inputMask='000.000.000-00'
                                    (emmitBlur)='sendGA("Form_Vida", "CPF")'
                                ></app-input>
                            </div>
                            <div class="field">
                                <app-input
                                    id='email'
                                    name='email'
                                    typeInput='email'
                                    placeholder='E-mail'
                                    labelName='E-mail'
                                    [form]='form'
                                    (emmitBlur)='sendGA("Form_Vida", "Confirmar e-mail")'
                                ></app-input>
                                <span
                                *ngIf='!changeEmail(form.get("email")?.value)'
                                class='control-error diferent-email'
                                >
                                INSIRA UM EMAIL VÁLIDO
                                </span>
                            </div>
                            <div class="field">
                                <app-input
                                    id='confirm_email'
                                    name='confirm_email'
                                    typeInput='email'
                                    placeholder='Confirmar e-mail'
                                    labelName='Confirmar e-mail'
                                    [form]='form'
                                    (emmitBlur)='sendGA("Form_Vida", "Confirmar e-mail")'
                                ></app-input>
                                <span
                                *ngIf='!changeEmail(form.get("confirm_email")?.value)'
                                class='control-error diferent-email'
                                >
                                INSIRA UM EMAIL VÁLIDO
                                </span>
                                <span
                                *ngIf=' form.get("confirm_email")?.value && (form.get("email")?.value !== form.get("confirm_email")?.value)'
                                class='control-error diferent-email'
                                >
                                OS EMAILS PRECISAM SER IGUAIS
                                </span>
                            </div>
                            <div class="field">
                                <app-input
                                    id='phone'
                                    name='phone'
                                    labelName='Telefone'
                                    placeholder='Telefone'
                                    inputMask='(00) 00000-0000'
                                    [form]='form'
                                    (emmitBlur)='sendGA("Form_Vida", "Telefone")'
                                ></app-input>
                            </div>
                            <div class="contact">
                                <h3>Como você prefere receber o nosso contato?</h3>
                                <div class="options">
                                    <div class="option">
                                        <app-radio
                                            id='whatsapp-contact'
                                            name="message"
                                            labelName='Mensagem'
                                            [value]="'whatsapp'"
                                            [form]='form'
                                            [changeModel]="changeRadio"
                                        ></app-radio>
                                    </div>
                                    <div class="option">
                                        <app-radio
                                            id='email-contact'
                                            name="message"
                                            labelName='E-mail'
                                            [value]="'email'"
                                            [form]='form'
                                            [changeModel]="changeRadio"
                                        ></app-radio>
                                    </div>
                                    <div class="option">
                                        <app-radio
                                            id='email-phone'
                                            name="message"
                                            labelName='Ligação'
                                            [value]="'ligacao'"
                                            [form]='form'
                                            [changeModel]="changeRadio"
                                        ></app-radio>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="action-button">
                            <button class="next-btn next-btn1" type="button" [disabled]="disabled" (click)="nextStep()">
                                Próximo
                            </button>
                        </div>
                    </div>
                    <div class="wrapper-steps__step" [ngClass]="step === 2 ? 'show' : 'hide'" id="step2">
                        <div class="form-content">
                            <div class="field">
                                <app-input
                                    id='zipCode'
                                    name='zipCode'
                                    labelName='CEP'
                                    placeholder='CEP'
                                    [form]='form'
                                    inputMask='00000-000'
                                    (emmitEvent)='getAddressByZipCode($event)'
                                    (emmitBlur)='sendGA("Form_Vida", "CEP")'
                                ></app-input>
                                <span *ngIf="form.get('zipCode')?.value && errorZipcode" class="error-cep">POR FAVOR, INSIRA UM CEP VÁLIDO</span>
                            </div>
                            <div class="field">
                                <app-input
                                    id='state'
                                    name='state'
                                    placeholder='Estado'
                                    labelName='Estado'
                                    [form]='form'
                                    [disabled]='true'
                                ></app-input>
                            </div>
                            <div class="field">
                                <app-input
                                    id='city'
                                    name='city'
                                    placeholder='Cidade'
                                    labelName='Cidade'
                                    [form]='form'
                                    [disabled]='true'
                                ></app-input>
                            </div>

                            <div class="row">
                                <div class="col-12 mb-3">
                                    <div class="options justify-content-start">
                                        <div class="option ps-0">
                                            <app-radio
                                                id='safeNew'
                                                name="safeType"
                                                labelName='Seguro Novo'
                                                [value]="'Seguro Novo'"
                                                [form]='form'
                                            ></app-radio>
                                        </div>
                                        <div class="option">
                                            <app-radio
                                                id='safeRenew'
                                                name="safeType"
                                                labelName='Já possuo seguro'
                                                [value]="'Já possuo seguro'"
                                                [form]='form'
                                            ></app-radio>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 mb-3">
                                    <div class="option mb-3">
                                        <app-checkbox
                                            id='interests_1'
                                            name="interests_1"
                                            labelName='Tenho interesse em seguro de Auto'
                                            [value]="'Tenho interesse em seguro de Auto'"
                                            [form]='form'
                                        ></app-checkbox>
                                    </div>
                                    <div class="option">
                                        <app-checkbox
                                            id='interests_2'
                                            name="interests_2"
                                            labelName='Tenho interesse em seguro de Residência'
                                            [value]="'Tenho interesse em seguro de Residência'"
                                            [form]='form'
                                        ></app-checkbox>
                                    </div>
                                </div>
                                <div class="col-12 mb-3">
                                    <div class="option">
                                        <app-checkbox
                                            id='consent'
                                            name="consent"
                                            labelName=""
                                            [form]='form'
                                        >
                                            <p>
                                                <strong>Declaro ciência sobre o tratamento dos dados inseridos por mim no formulário,
                                                        na forma estabelecida pela <a href="https://www.allianz.com.br/politicas-do-sistema.html#privacidade" class="custom-link">Politica de Privacidade</a> e
                                                    <a href="https://www.allianz.com.br/politicas-do-sistema.html#cookies" class="custom-link">Politica de Cookies</a> da Allianz.</strong>
                                                Autorizo a Allianz a compartilhar minhas informações com corretores credenciados para
                                                cotação de produtos e serviços. Além disso, aceito receber comunicações sobre promoções
                                                da Allianz e seus parceiros.
                                                <span
                                                    class="custom-tooltip btn-open"
                                                    (click)="openModal()"
                                                >i</span>
                                            </p>
                                        </app-checkbox>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="action-button">
                            <button class="submit-btn mb-3" type="submit" [disabled]="disabled">
                                QUERO UMA COTAÇÃO
                            </button>
                            <button class="back-step" type="button" (click)="backStep()">
                                voltar
                            </button>
                        </div>
                    </div>
                    <div class="wrapper-steps__step" [ngClass]="step === 3 ? 'show' : 'hide'" id="step3">
                        <div *ngIf="formFeedback" class="form-content">
                            <h5 class="title">{{ formFeedback.title }}</h5>
                            <p [innerHTML]="formFeedback.subtitle"></p>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </app-banner-form>
    <section class="pt-5">
        <div class="wrapper-description">
            <div class="wrapper-container">
                <div class="container">
                    <div class="text-life">
                        <h2 class="title-section">Tranquilidade e Proteção Financeira</h2>
                        <p class="description-section text-center">
                            Ter um seguro de vida é contar com a segurança de que você e sua família estarão protegidos, caso um imprevisto aconteça.
                        </p>
                        <p class="description-section text-center">
                            Temos coberturas e assistências que cuidam de você hoje e garantem um amanhã mais tranquilo para quem você ama.
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <app-tabs>
            <app-tab *ngFor="let tab of tabs" [tabTitle]="tab.title" [active]="tab.active">
                <div
                    *ngIf="tab.content === 'today'"
                    class="safe"
                >
                    <div class="wrapper-container">
                        <div class="container">
                            <h2 class="title-section mb-5">Coberturas</h2>
                            <div class="row justify-content-center">
                                <div class="col-6 col-md-3 mb-4 mx-auto mx-md-0" *ngFor="let card of cards.today.toppings">
                                    <app-card classCustom="life" [figure]="card.img" [title]="card.title" [titleModal]="card.titleModal" [description]="card.description"></app-card>
                                </div>
                            </div>
                            <h2 class="title-section my-5">Assistências</h2>
                            <div class="row justify-content-center">
                                <div class="col-6 col-md-3 mb-4 mx-auto mx-md-0" *ngFor="let card of cards.today.assists">
                                    <app-card classCustom="life" [figure]="card.img" [title]="card.title" [titleModal]="card.titleModal" [description]="card.description"></app-card>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    *ngIf="tab.content === 'tomorrow'"
                    class="safe"
                >
                    <div class="wrapper-container">
                        <div class="container">
                            <h2 class="title-section mb-5">Coberturas</h2>
                            <div class="row justify-content-center">
                                <div class="col-6 col-md-3 mb-4 mx-auto mx-md-0" *ngFor="let card of cards.tomorrow.toppings">
                                    <app-card classCustom="life" [figure]="card.img" [title]="card.title" [titleModal]="card.titleModal" [description]="card.description"></app-card>
                                </div>
                            </div>
                            <h2 class="title-section my-5">Assistências</h2>
                            <div class="row justify-content-center">
                                <div class="col-6 col-md-3 mb-4 mx-auto mx-md-0" *ngFor="let card of cards.tomorrow.assists">
                                    <app-card classCustom="life" [figure]="card.img" [title]="card.title" [titleModal]="card.titleModal" [description]="card.description"></app-card>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </app-tab>
        </app-tabs>
    </section>
    <app-banner-text [img]="'/assets/images/seguro-vida.jpg'" [title]="'Benefícios de contratar o Allianz Vida'" [services]="services"></app-banner-text>
    <div class="toggle-safes">
        <app-toggle-safe
            [img]="'/assets/images/safe-residence.jpg'"
            [title]="'Proteja também sua casa ou apartamento'"
            [text]="textSafeResidence"
            [link]="'/residencia'"
        ></app-toggle-safe>
        <app-toggle-safe
            [img]="'/assets/images/safe-auto.jpg'"
            [title]="'Proteja também seu carro'"
            [text]="textSafeAuto"
            [link]="'/auto'"
            [isEven]="true"
        ></app-toggle-safe>
    </div>
</div>

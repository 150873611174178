import { Component, OnInit, ViewEncapsulation } from '@angular/core'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { ModalTooltipComponent } from '../../components/modals/modal-tooltip/modal-tooltip.component'

@Component({
	selector: 'app-palmeiras-insurance',
	templateUrl: './palmeiras-insurance.component.html',
	styleUrls: ['./palmeiras-insurance.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class PalmeirasInsuranceComponent implements OnInit {
	hubUtm = ''
	errorZipcode = false
	disabled = true
	step = 1
	formFeedback = {
		title: '',
		subtitle: '',
	}

	titleBanner = `Cliente <strong class="red">Rappi Prime</strong> pode ter o <strong>seguro Allianz Vida</strong> a partir de <strong class="red">R$23,00</strong><strong> por ano!</strong>`
	descriptionBanner = `<strong>Allianz Vida.</strong> <br /> Para você aproveitar o hoje <span class="red">e proteger o amanhã!</span>`
	tabs = [
		{
			title: 'Vida',
			active: false,
			content: 'life',
		},
		{
			title: 'Residência',
			active: false,
			content: 'residence',
		},
		{
			title: 'Auto',
			active: true,
			content: 'auto',
		},
	]

	constructor(private modal: NgbModal) {}

	ngOnInit(): void {
		window.gtag('event', 'conversion', {
			send_to: 'AW-1031001625/DZ4jCPW6-pgYEJmsz-sD',
			event_callback: window.location,
		})
	}

	openModal(): void {
		this.modal.open(ModalTooltipComponent, {
			modalDialogClass: 'modal-tooltip',
		})
	}
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { FormGroup } from '@angular/forms'
import { moc } from 'src/app/pages/car-insurance/car-insurance.component'

@Component({
	selector: 'app-select-autocomplete',
	templateUrl: './select-autocomplete.component.html',
	styleUrls: ['./select-autocomplete.component.scss'],
})
export class SelectAutocompleteComponent implements OnInit {
	public showPass: boolean = false
	public time: any = 0

	@Input() form: FormGroup = new FormGroup(Input())
	@Input() id?: string
	@Input() name: string | number | null = ''
	@Input() placeholder?: string
	@Input() labelName?: string
	@Input() callout?: string
	@Input() inputModel: moc | undefined
	@Input() showLabel?: boolean = false
	@Input() disabled?: boolean = false
	@Input() showLoading?: boolean = false
	@Input() itemsList: Array<moc> = []
	@Output() search: EventEmitter<any> = new EventEmitter()
	@Output() select: EventEmitter<any> = new EventEmitter()

	constructor() {}

	ngOnInit(): void {}

	toggleInputPass(): void {
		this.showPass = !this.showPass
	}

	onChange(event: any, value?: string): void {
		this.showLoading = true;
		this.search.emit(value)
	}

	selectItem(item: any) {
		this.inputModel = item.label
		this.itemsList = []
		this.select.emit(item)
	}

	semDado(){
		clearTimeout(this.time)
		this.time = setTimeout(() => {
			this.itemsList = []
		}, 2400);
	}
}
